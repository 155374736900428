import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material';
import { Plan } from 'src/api/models/Plan';
import accountService from '../../../services/AccountService';
import AccountService from '../../../services/AccountService';

const StatusTiles: React.FC = () => {
  const { account, loading } = accountService.useStore((state) => state);
  const [plan, setUserPlan] = useState<Plan | null>(null);

  useEffect(() => {
    (async function () {
      try {
        const accountData = await accountService.getAccountData();
        if (accountData && accountData.plan) {
          setUserPlan(accountData.plan);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const totalRemaining = useMemo(
    () => (account ? AccountService.getTotalDataRemaining() : 0),
    [account]
  );

  if (loading) return <>Loading...</>;

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={6}>
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent>
            {plan ? (
              <Box>
                <Typography variant="h5" gutterBottom>
                  Upgrade Your Plan
                </Typography>
                <Typography paragraph>
                  Your current plan gives you access to {plan.allowedProxyCount}{' '}
                  {plan.allowedProxyCount === 1 ? 'proxy' : 'proxies'}.
                  <br />
                  Upgrade your plan to increase the number of proxies you can
                  use.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href="/management/plans"
                >
                  Upgrade Plan
                </Button>
              </Box>
            ) : (
              <Box>
                <Typography variant="h5" gutterBottom>
                  Purchase a Plan
                </Typography>
                <Typography paragraph>
                  You currently do not have a subscription. Purchase a plan to
                  start using PuroProxies.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href="/management/plans"
                >
                  Purchase Plan
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent>
            {totalRemaining > 0 ? (
              <Box>
                <Typography variant="h5" gutterBottom>
                  Purchase More Data
                </Typography>
                <Typography paragraph>
                  You have data available.
                  <br /> You can always purchase a one-off data pack whenever
                  you need more data.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href="/management/data-packs"
                >
                  Purchase Data Pack
                </Button>
              </Box>
            ) : (
              <Box>
                <Typography variant="h5" gutterBottom>
                  Purchase a Data Plan
                </Typography>
                <Typography paragraph>
                  You currently do not have a data plan or data available.
                  Purchase a plan to start using data.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href="/management/plans"
                >
                  Purchase Data Plan
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StatusTiles;
