import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dataUsageService from '../../../services/DataUsageService';
import AccountService from '../../../services/AccountService';
import UsageTile from './UsageTile';
import DataUsageGraph from './DataUsageGraph';
import {
  DateInputContainer,
  LoadingIndicatorContainer,
  StyledContainer
} from './styles';
import { DataUsageRecord } from 'src/api/models/DataUsageRecord';

const getDate = (daysToAdd: number): Date => {
  const d = new Date();
  d.setDate(d.getDate() + daysToAdd);
  return d;
};

const DataUsageDashboard: React.FC = () => {
  const [fromDate, setFromDate] = useState<Date>(getDate(-30));
  const [toDate, setToDate] = useState<Date>(new Date());
  const [records, setRecords] = useState<DataUsageRecord[]>([]);

  const { loading, error, averageUsage, averageUsageLoading } =
    dataUsageService.useStore();

  const fetchUsageData = async (start: Date, end: Date) => {
    try {
      const fetchedRecords = await dataUsageService.getOverallUsage(start, end);
      setRecords(fetchedRecords);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAverageUsage = async () => {
    try {
      await dataUsageService.getAverage30daysUsage();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchUsageData(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    fetchAverageUsage();
  }, []);

  const loadingIndicator = (
    <LoadingIndicatorContainer>
      <CircularProgress />
    </LoadingIndicatorContainer>
  );

  return (
    <StyledContainer>
      <>
        <Grid container spacing={1}>
          <UsageTile
            title="Available Data"
            value={AccountService.getTotalDataRemaining()}
            loading={averageUsageLoading}
          />
          <UsageTile
            title="Used Data"
            value={
              AccountService.getTotalDataAllocated() -
              AccountService.getTotalDataRemaining()
            }
            loading={averageUsageLoading}
          />
          <UsageTile
            title="Daily Average Usage"
            value={averageUsage}
            loading={averageUsageLoading}
          />
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateInputContainer container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="From"
                value={fromDate}
                onChange={(newValue) => newValue && setFromDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="To"
                value={toDate}
                onChange={(newValue) => newValue && setToDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </DateInputContainer>
        </LocalizationProvider>
        <Divider />
        {error ? (
          <Typography color="error" mt={2}>
            Error loading data: {error.message}
          </Typography>
        ) : (
          <>
            {loading ? loadingIndicator : <DataUsageGraph records={records} />}
          </>
        )}
      </>
    </StyledContainer>
  );
};

export default DataUsageDashboard;
