/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataUsage,
  ErrorInfo,
} from '../models/index';
import {
    DataUsageFromJSON,
    DataUsageToJSON,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
} from '../models/index';

export interface ApiDataUsageGetRequest {
    from?: Date;
    to?: Date;
}

export interface ApiDataUsageProxyIdGetRequest {
    proxyId: string;
    from?: Date;
    to?: Date;
}

/**
 * 
 */
export class DatausageApi extends runtime.BaseAPI {

    /**
     */
    async apiDataUsageGetRaw(requestParameters: ApiDataUsageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataUsage>> {
        const queryParameters: any = {};

        if (requestParameters['from'] != null) {
            queryParameters['from'] = (requestParameters['from'] as any).toISOString();
        }

        if (requestParameters['to'] != null) {
            queryParameters['to'] = (requestParameters['to'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/data_usage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataUsageFromJSON(jsonValue));
    }

    /**
     */
    async apiDataUsageGet(requestParameters: ApiDataUsageGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataUsage> {
        const response = await this.apiDataUsageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDataUsageProxyIdGetRaw(requestParameters: ApiDataUsageProxyIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataUsage>> {
        if (requestParameters['proxyId'] == null) {
            throw new runtime.RequiredError(
                'proxyId',
                'Required parameter "proxyId" was null or undefined when calling apiDataUsageProxyIdGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['from'] != null) {
            queryParameters['from'] = (requestParameters['from'] as any).toISOString();
        }

        if (requestParameters['to'] != null) {
            queryParameters['to'] = (requestParameters['to'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/data_usage/{proxyId}`.replace(`{${"proxyId"}}`, encodeURIComponent(String(requestParameters['proxyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataUsageFromJSON(jsonValue));
    }

    /**
     */
    async apiDataUsageProxyIdGet(requestParameters: ApiDataUsageProxyIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataUsage> {
        const response = await this.apiDataUsageProxyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
