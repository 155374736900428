import { Alert, Box, CircularProgress, Link, TableCell } from '@mui/material';
import colors from 'src/colors';
import styled from 'styled-components';

export const ErrorAlertBox = styled(Alert)`
  margin: 4rem 2rem 0 2rem;
  background-color: ${colors.brightRed};
`;
export const WarningAlertBox = styled(Alert)`
  margin: 4rem 2rem 0 2rem;
  background-color: ${colors.warn};
`;
export const NoUnderlineLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;
