import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import accountService from 'src/services/AccountService';

const ProtectedRoute = ({ children }) => {
  const { account, loading, getCount } = accountService.useStore(
    (state) => state
  );

  useEffect(() => {
    (async () => {
      await accountService.getAccountData();
    })();
  }, []);

  if (!account && !loading && getCount > 0) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};

export default ProtectedRoute;
