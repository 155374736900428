/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorInfo,
  Plan,
} from '../models/index';
import {
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    PlanFromJSON,
    PlanToJSON,
} from '../models/index';

export interface ApiPlansPostRequest {
    plan: Plan;
}

/**
 * 
 */
export class PlansApi extends runtime.BaseAPI {

    /**
     * Get plans
     */
    async apiPlansGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Plan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanFromJSON));
    }

    /**
     * Get plans
     */
    async apiPlansGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Plan>> {
        const response = await this.apiPlansGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPlansPostRaw(requestParameters: ApiPlansPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters['plan'] == null) {
            throw new runtime.RequiredError(
                'plan',
                'Required parameter "plan" was null or undefined when calling apiPlansPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanToJSON(requestParameters['plan']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async apiPlansPost(requestParameters: ApiPlansPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plan> {
        const response = await this.apiPlansPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
