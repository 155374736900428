import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SettingsIcon from '@mui/icons-material/Settings';
import TableCopyObject from './TableCopyObject';
import { toProxyRow } from '../../../util/ProxyUtil';
import UsernameDialog from './UsernameDialog';
import PasswordDialog from './PasswordDialog';
import { StyledBox, StyledIconButton, StyledTableCell } from './styles';
import proxiesService from '../../../services/ProxiesService';
import { Proxy } from 'src/api/models/Proxy';

interface ProxyTableProps {
  selectedProxies: string[];
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelect: (id: string) => void;
}

export const ProxyTable: React.FC<ProxyTableProps> = ({
  selectedProxies,
  handleSelectAll,
  handleSelect
}) => {
  const { proxies, loading, error, fetchProxies } = proxiesService.useStore(
    (state) => ({
      proxies: state.proxies,
      loading: state.loading,
      error: state.error,
      fetchProxies: state.fetchProxies
    })
  );
  const [usernameDialogOpen, setUsernameDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [selectedProxy, setSelectedProxy] = useState<Proxy | null>(null);

  useEffect(() => {
    fetchProxies();
  }, [fetchProxies]);

  const handleOpenUsernameDialog = (proxy: Proxy) => {
    setSelectedProxy(proxy);
    setUsernameDialogOpen(true);
  };

  const handleUsernameDialogClose = async (success: boolean) => {
    setUsernameDialogOpen(false);
    setSelectedProxy(null);
    if (success) {
      await fetchProxies();
    }
  };

  const handlePasswordDialogClose = async (success: boolean) => {
    setPasswordDialogOpen(false);
    if (success) {
      await fetchProxies();
    }
  };

  return (
    <StyledBox>
      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
        <Table sx={{ minWidth: 700 }} aria-label="proxy table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedProxies.length > 0 &&
                    selectedProxies.length < proxies.length
                  }
                  checked={
                    proxies.length > 0 &&
                    selectedProxies.length === proxies.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>HTTP Port</TableCell>
              <TableCell>SOCKS5 Port</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>
                Password
                <StyledIconButton onClick={() => setPasswordDialogOpen(true)}>
                  <SettingsIcon />
                </StyledIconButton>
              </TableCell>
              <TableCell>Public IP</TableCell>
              <TableCell>Network Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <Typography color="error">{error}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              proxies.map((proxy) => (
                <TableRow
                  key={proxy.id}
                  selected={selectedProxies.includes(proxy.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedProxies.includes(proxy.id)}
                      onChange={() => handleSelect(proxy.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TableCopyObject
                      text={toProxyRow(proxy.socks5Address).ip}
                    />
                  </TableCell>
                  <TableCell>
                    <TableCopyObject
                      text={toProxyRow(proxy.httpAddress).port}
                    />
                  </TableCell>
                  <TableCell>
                    <TableCopyObject
                      text={toProxyRow(proxy.socks5Address).port}
                    />
                  </TableCell>
                  <TableCell>
                    <TableCopyObject text={proxy.username} />
                    {/*<Box display="flex" alignItems="center">*/}
                    {/*  <TableCopyObject text={proxy.username} />*/}
                    {/*  <StyledIconButton*/}
                    {/*    onClick={() => handleOpenUsernameDialog(proxy)}*/}
                    {/*  >*/}
                    {/*    <SettingsIcon />*/}
                    {/*  </StyledIconButton>*/}
                    {/*</Box>*/}
                  </TableCell>
                  <TableCell>
                    <TableCopyObject text={proxy.password} />
                  </TableCell>
                  <TableCell>{proxy.publicIp}</TableCell>
                  <TableCell>{proxy.networkName}</TableCell>
                  <StyledTableCell align="left">
                    {proxy.status === 'Online' ? (
                      <CheckCircleOutlineIcon color="success" />
                    ) : (
                      <HighlightOffIcon color="error" />
                    )}
                  </StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <UsernameDialog
        open={usernameDialogOpen}
        handleClose={handleUsernameDialogClose}
        proxy={selectedProxy}
      />

      <PasswordDialog
        open={passwordDialogOpen}
        handleClose={handlePasswordDialogClose}
      />
    </StyledBox>
  );
};
