import React from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { Product } from 'src/api/models/Product';
import { ShoppingBasket } from '@mui/icons-material';
import { Plan } from 'src/api/models/Plan';
import { StyledCard } from './styles';

export type BasketItem = {
  product?: Product | undefined;
  plan?: Plan | undefined;
  quantity: number;
};

type Props = {
  basket: Map<string, BasketItem>;
  onCheckout: () => void;
  updateBasket: (newBasket: Map<string, BasketItem>) => void;
};

const Basket: React.FC<Props> = ({ basket, onCheckout, updateBasket }) => {
  const items = Array.from(basket.entries());

  let totalPrice = 0;
  let monthlyPrice = 0;

  items.forEach(([id, item]) => {
    const { product, plan, quantity } = item;

    if (product) {
      totalPrice += (product.costCents / 100) * quantity;
    } else {
      totalPrice += (plan.monthlyCostCents / 100) * quantity;
      monthlyPrice += (plan.monthlyCostCents / 100) * quantity;
    }
  });

  const removeItem = (id: string) => {
    const newBasket = new Map(basket);
    newBasket.delete(id);
    updateBasket(newBasket);
  };

  return (
    <StyledCard>
      <Box>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ fontWeight: 'medium' }}
        >
          Your Cart
        </Typography>
        <Divider variant="middle" />
        <List sx={{ mb: 2 }}>
          {items.length > 0 ? (
            items.map(([id, item]) => {
              const { product, plan, quantity } = item;
              const actualItem = product || plan;

              if (plan) {
                return (
                  <ListItem
                    key={id}
                    sx={{ py: 1, borderBottom: '1px solid #e0e0e0' }}
                  >
                    <ListItemText
                      primary={actualItem.name}
                      primaryTypographyProps={{
                        fontWeight: 'bold',
                        fontSize: '1.1rem'
                      }}
                      secondary={`${quantity} x $${((plan.monthlyCostCents || product.costCents) / 100).toFixed(2)}${(product && product.subscription) || plan ? ' per month' : ''}`}
                    />
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => removeItem(id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem
                    key={id}
                    sx={{ py: 1, borderBottom: '1px solid #e0e0e0' }}
                  >
                    <ListItemText
                      primary={actualItem.name}
                      primaryTypographyProps={{
                        fontWeight: 'bold',
                        fontSize: '1.1rem'
                      }}
                      secondary={`${quantity} x $${(product.costCents / 100).toFixed(2)}`}
                    />
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => removeItem(id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </ListItem>
                );
              }
            })
          ) : (
            <Typography sx={{ p: 2, textAlign: 'center' }}>
              Your cart is empty.
            </Typography>
          )}
        </List>
      </Box>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Total today: ${totalPrice.toFixed(2)} (excl. tax)
        </Typography>
        {monthlyPrice > 0 && (
          <Typography
            variant="subtitle1"
            sx={{ color: 'secondary.main', mt: 1 }}
          >
            Ongoing Monthly Cost: ${monthlyPrice.toFixed(2)} (excl. tax)
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onCheckout}
          startIcon={<ShoppingBasket />}
          sx={{
            mt: 2,
            py: 1.5,
            backgroundColor: items.length > 0 ? 'primary.main' : 'grey.400',
            '&:hover': {
              backgroundColor: items.length > 0 ? 'primary.dark' : 'grey.500'
            }
          }}
          disabled={items.length === 0}
        >
          Checkout
        </Button>
      </Box>
    </StyledCard>
  );
};

export default Basket;
