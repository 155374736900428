import React, { useState } from 'react';
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Fade,
  FormControlLabel,
  Link,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { register } from '../../firebase-auth';

const RegisterForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    if (!acceptedTerms) {
      setSnackbarMessage('Please accept the terms & conditions');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    if (password !== confirmPassword) {
      setSnackbarMessage("Passwords don't match!");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      await register(email, password);
      setSnackbarMessage('Registration successful!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
      setTimeout(() => navigate('/dashboards/proxies'), 1500);
    } catch (error) {
      console.log(error.code);
      switch (error.code) {
        case 'auth/invalid-email':
          setSnackbarMessage('Invalid email, please try again. ');
          break;
        case 'auth/email-already-in-use':
          setSnackbarMessage('Email already in use, please try again. ');
          break;
        default:
          setSnackbarMessage('Failed to register, please try again. ');
      }
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Fade in={true} timeout={500}>
        <div>
          <Button
            startIcon={<ArrowBackIcon />}
            sx={{ alignSelf: 'flex-start', marginTop: 2 }}
            onClick={() => navigate('/auth')}
          >
            Back
          </Button>
          <Paper
            elevation={6}
            sx={{
              mt: 1,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 2
            }}
          >
            <Avatar
              src="img/icons/logo512_trans.png"
              sx={{ width: 120, height: 120, mb: 4 }}
            />
            <Typography
              component="h1"
              variant="h4"
              sx={{
                mb: 3,
                fontWeight: 600,
                color: theme.palette.secondary.main,
                letterSpacing: 2,
                textTransform: 'uppercase',
                textDecoration: 'underline',
                textDecorationColor: theme.palette.primary.light,
                textDecorationThickness: 4,
                textUnderlineOffset: 6
              }}
            >
              Register
            </Typography>
            <Box
              component="form"
              onSubmit={handleRegister}
              noValidate
              sx={{ width: '100%' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                variant="outlined"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value={acceptedTerms}
                    onChange={(e) =>
                      setAcceptedTerms(Boolean(e.currentTarget.value))
                    }
                  />
                }
                label={
                  <Typography>
                    I accept the Puro Proxies{' '}
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://puroproxies.com/terms"
                    >
                      Terms & Conditions
                    </Link>
                  </Typography>
                }
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                disabled={
                  !acceptedTerms ||
                  email.length === 0 ||
                  password.length === 0 ||
                  confirmPassword.length === 0
                }
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  backgroundColor: theme.palette.primary.dark,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main
                  }
                }}
              >
                Register
              </Button>
            </Box>
          </Paper>
        </div>
      </Fade>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RegisterForm;
