/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAccountRequest
 */
export interface UpdateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequest
     */
    proxyPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountRequest
     */
    affiliateCode?: string;
}

/**
 * Check if a given object implements the UpdateAccountRequest interface.
 */
export function instanceOfUpdateAccountRequest(value: object): value is UpdateAccountRequest {
    return true;
}

export function UpdateAccountRequestFromJSON(json: any): UpdateAccountRequest {
    return UpdateAccountRequestFromJSONTyped(json, false);
}

export function UpdateAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAccountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'proxyPassword': json['proxyPassword'] == null ? undefined : json['proxyPassword'],
        'affiliateCode': json['affiliateCode'] == null ? undefined : json['affiliateCode'],
    };
}

export function UpdateAccountRequestToJSON(value?: UpdateAccountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'proxyPassword': value['proxyPassword'],
        'affiliateCode': value['affiliateCode'],
    };
}

