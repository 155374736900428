/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataUsageRecord
 */
export interface DataUsageRecord {
    /**
     * 
     * @type {number}
     * @memberof DataUsageRecord
     */
    uploaded: number;
    /**
     * 
     * @type {number}
     * @memberof DataUsageRecord
     */
    downloaded: number;
    /**
     * 
     * @type {Date}
     * @memberof DataUsageRecord
     */
    day: Date;
}

/**
 * Check if a given object implements the DataUsageRecord interface.
 */
export function instanceOfDataUsageRecord(value: object): value is DataUsageRecord {
    if (!('uploaded' in value) || value['uploaded'] === undefined) return false;
    if (!('downloaded' in value) || value['downloaded'] === undefined) return false;
    if (!('day' in value) || value['day'] === undefined) return false;
    return true;
}

export function DataUsageRecordFromJSON(json: any): DataUsageRecord {
    return DataUsageRecordFromJSONTyped(json, false);
}

export function DataUsageRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataUsageRecord {
    if (json == null) {
        return json;
    }
    return {
        
        'uploaded': json['uploaded'],
        'downloaded': json['downloaded'],
        'day': (new Date(json['day'])),
    };
}

export function DataUsageRecordToJSON(value?: DataUsageRecord | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uploaded': value['uploaded'],
        'downloaded': value['downloaded'],
        'day': ((value['day']).toISOString().substring(0,10)),
    };
}

