/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Account,
  ErrorInfo,
  UpdateAccountRequest,
} from '../models/index';
import {
    AccountFromJSON,
    AccountToJSON,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    UpdateAccountRequestFromJSON,
    UpdateAccountRequestToJSON,
} from '../models/index';

export interface ApiAccountsMePutRequest {
    updateAccountRequest: UpdateAccountRequest;
}

/**
 * 
 */
export class AccountsApi extends runtime.BaseAPI {

    /**
     */
    async apiAccountsMeGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/accounts/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async apiAccountsMeGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.apiAccountsMeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccountsMePutRaw(requestParameters: ApiAccountsMePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters['updateAccountRequest'] == null) {
            throw new runtime.RequiredError(
                'updateAccountRequest',
                'Required parameter "updateAccountRequest" was null or undefined when calling apiAccountsMePut().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/accounts/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAccountRequestToJSON(requestParameters['updateAccountRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async apiAccountsMePut(requestParameters: ApiAccountsMePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.apiAccountsMePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
