import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { DataUsageRecord } from 'src/api/models/DataUsageRecord';
import { ChartContainer } from './styles';

const formatDate = (d: Date) => {
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
};

interface DataUsageGraphProps {
  records: DataUsageRecord[];
}

const DataUsageGraph: React.FC<DataUsageGraphProps> = ({ records }) => {
  const formattedData = records
    .map((r) => ({
      day: formatDate(new Date(r.day)),
      uploaded: r.uploaded / 1024 / 1024,
      downloaded: r.downloaded / 1024 / 1024,
      total: (r.uploaded + r.downloaded) / 1024 / 1024
    }))
    .sort((a, b) => new Date(b.day).getTime() - new Date(a.day).getTime());

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={formattedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" reversed />
          <YAxis label={{ value: 'MB', angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="uploaded"
            stroke="#8884d8"
            activeDot={{ r: 9 }}
            strokeWidth={3}
          />
          <Line
            type="monotone"
            dataKey="downloaded"
            stroke="#82ca9d"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default DataUsageGraph;
