import { Navigate } from 'react-router-dom';
import accountService from 'src/services/AccountService';

const AffiliateRedirect = ({ children }) => {
  const { account, loading } = accountService.useStore((state) => state);

  if (account == null || loading) {
    return <></>;
  }
  if (!account.affiliateCode) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AffiliateRedirect;
