/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SubscriptionStatus = {
    Active: 'Active',
    Cancelled: 'Cancelled',
    Expired: 'Expired',
    Pending: 'Pending'
} as const;
export type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];


export function instanceOfSubscriptionStatus(value: any): boolean {
    for (const key in SubscriptionStatus) {
        if (Object.prototype.hasOwnProperty.call(SubscriptionStatus, key)) {
            if (SubscriptionStatus[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SubscriptionStatusFromJSON(json: any): SubscriptionStatus {
    return SubscriptionStatusFromJSONTyped(json, false);
}

export function SubscriptionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionStatus {
    return json as SubscriptionStatus;
}

export function SubscriptionStatusToJSON(value?: SubscriptionStatus | null): any {
    return value as any;
}

