export interface FAQItem {
  question: string;
  answer: string;
}

export interface FAQCategory {
  category: string;
  items: FAQItem[];
}

export const faqData: FAQCategory[] = [
  {
    category: 'Basics',
    items: [
      {
        question: 'Do the IP plans come with any data?',
        answer:
          'Each plan comes with some data for you to validate that you are able to use the proxies for your given use case. If you wish to use more data, additional data packs will need to be purchased.'
      },
      {
        question: 'What is your return policy?',
        answer:
          'You can request a refund within 14 days of purchase. Please note that data usage will be deducted from the final refund amount.\n\nTo request a refund, please contact our customer support team via live chat or email us at support@puroproxies.com.'
      }
    ]
  },
  {
    category: 'Using Your Proxy',
    items: [
      {
        question: 'What protocols do the proxies support?',
        answer: 'Our proxies support both HTTP and SOCKS5 protocols.'
      },
      {
        question: 'Are there any use case limitations?',
        answer:
          'You can use our proxies for any legal purpose.\nHowever, we strictly prohibit the use of our proxies for any illegal activities.\nif you are found to be doing this, we reserve the right to cancel any of your services with us without a refund and forbid you from using our services.\nIf the severity of what has been done requires it, it will be reported to the appropriate authorities.'
      }
    ]
  }
];
