import { useEffect } from 'react';
import accountService from 'src/services/AccountService';
import { Navigate } from 'react-router-dom';
import LoadingBlock from './LoadingBlock';

const RootRedirect = () => {
  const { account, loading, getCount } = accountService.useStore(
    (state) => state
  );

  useEffect(() => {
    (async () => {
      await accountService.getAccountData();
    })();
  }, []);

  if (loading || getCount <= 0) {
    return <LoadingBlock />;
  }

  if (account) {
    return <Navigate to="/dashboards/proxies" replace />;
  } else {
    return <Navigate to="/auth" replace />;
  }
};

export default RootRedirect;
