/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Product,
} from '../models/index';
import {
    ProductFromJSON,
    ProductToJSON,
} from '../models/index';

export interface ApiProductsPostRequest {
    product: Product;
}

/**
 * 
 */
export class ProductsApi extends runtime.BaseAPI {

    /**
     */
    async apiProductsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Product>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
    }

    /**
     */
    async apiProductsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Product>> {
        const response = await this.apiProductsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProductsPostRaw(requestParameters: ApiProductsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters['product'] == null) {
            throw new runtime.RequiredError(
                'product',
                'Required parameter "product" was null or undefined when calling apiProductsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductToJSON(requestParameters['product']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     */
    async apiProductsPost(requestParameters: ApiProductsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.apiProductsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
