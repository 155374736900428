import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FAQCategory, faqData } from './faqData';
import ThemeProvider from '../../../theme/ThemeProvider';

const Faq: React.FC = () => {
  const theme = useTheme();
  return (
    <ThemeProvider>
      <Container>
        <Box sx={{ marginTop: 4 }}>
          <Grid container spacing={4}>
            {faqData.map((category: FAQCategory, categoryIndex: number) => (
              <Grid item xs={12} md={6} key={categoryIndex}>
                <Card sx={{ borderRadius: 2, boxShadow: theme.shadows[1] }}>
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ marginTop: 2, marginBottom: 2 }}
                    >
                      {category.category}
                    </Typography>
                    {category.items.map((item, itemIndex) => (
                      <Accordion
                        key={itemIndex}
                        sx={{
                          marginBottom: 2,
                          borderRadius: 1,
                          boxShadow: theme.shadows[1]
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${categoryIndex}-${itemIndex}-content`}
                          id={`panel${categoryIndex}-${itemIndex}-header`}
                          sx={{
                            backgroundColor: 'background.default',
                            borderRadius: 1
                          }}
                        >
                          <Typography>{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            backgroundColor: 'background.paper',
                            borderRadius: 1
                          }}
                        >
                          <Typography>{item.answer}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Faq;
