import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import ProxyTable from './DeviceTable';
import proxiesService from '../../../services/ProxiesService';

function AdminProxies() {
  const { proxies, loading } = proxiesService.useStore((state) => state);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async function () {
      try {
        await proxiesService.fetchProxies();
      } catch (e) {
        setError(e);
      }
    })();
  }, []);

  if (error) return <p>Error loading data: {error}</p>;

  return (
    <Box sx={{ p: 3 }}>
      <ProxyTable proxies={proxies} loading={loading} error={error} />
    </Box>
  );
}

export default AdminProxies;
