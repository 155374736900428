import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { bounceAnimation } from './styles';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(4);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, seconds * 1000);

    const countdownInterval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(countdownInterval);
    };
  }, [navigate, seconds]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <Grid item xs={12} textAlign="center">
        <Typography variant="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" gutterBottom>
          Page Not Found
        </Typography>
        <Box mt={4}>
          <Typography variant="body1">
            Oops! The page you are looking for does not exist.
          </Typography>
          <Typography variant="body1">
            You will be redirected in {seconds} seconds...
          </Typography>
        </Box>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/"
            style={{
              animation: `${bounceAnimation} 2s infinite`
            }}
          >
            Go Back Home
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotFound;
