import React, { useState } from 'react';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { ContentCopyTwoTone } from '@mui/icons-material';

const TableCopyObject: React.FC<{ text: string }> = ({ text }) => {
  const [tooltipTitle, setTooltipTitle] = useState('Copy to clipboard');
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (text) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTooltipTitle('Copied!');
      setTimeout(() => {
        setCopied(false);
        setTooltipTitle('Copy to clipboard');
      }, 1000);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        p={1}
        bgcolor="background.paper"
        borderRadius={1}
        sx={{
          backgroundColor: '#f0f0f0',
          border: '1px solid #d3d3d3',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        <Typography
          variant="body1"
          component="span"
          sx={{ flexGrow: 1, pr: 1 }}
        >
          {text}
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Tooltip
          key={tooltipTitle}
          title={tooltipTitle}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: copied ? 'success.main' : 'default',
                color: 'white'
              }
            }
          }}
        >
          <Box
            onClick={handleCopy}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              p: 0
            }}
          >
            <IconButton aria-label="copy" size="small" sx={{ p: 0 }}>
              <ContentCopyTwoTone fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default TableCopyObject;
