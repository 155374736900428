/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckoutRequest,
  CheckoutResponse,
} from '../models/index';
import {
    CheckoutRequestFromJSON,
    CheckoutRequestToJSON,
    CheckoutResponseFromJSON,
    CheckoutResponseToJSON,
} from '../models/index';

export interface ApiCheckoutPostRequest {
    checkoutRequest: CheckoutRequest;
}

/**
 * 
 */
export class CheckoutApi extends runtime.BaseAPI {

    /**
     *  
     */
    async apiCheckoutPostRaw(requestParameters: ApiCheckoutPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutResponse>> {
        if (requestParameters['checkoutRequest'] == null) {
            throw new runtime.RequiredError(
                'checkoutRequest',
                'Required parameter "checkoutRequest" was null or undefined when calling apiCheckoutPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/checkout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckoutRequestToJSON(requestParameters['checkoutRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutResponseFromJSON(jsonValue));
    }

    /**
     *  
     */
    async apiCheckoutPost(requestParameters: ApiCheckoutPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutResponse> {
        const response = await this.apiCheckoutPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
