import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import ProductTable from './ProductTable';
import productsService from 'src/services/ProductsService';
import MutateProduct from './MutateProduct';
import { Product } from 'src/api/models/Product';
import { v4 as uuidv4 } from 'uuid';

function newProduct(): Product {
  return {
    id: uuidv4(),
    name: '',
    description: '',
    productType: 'DataPack',
    stripeId: '',
    stripePriceId: '',
    subscription: false,
    dataPackBytesSize: 0,
    costCents: 0,
    active: false
  };
}

function AdminProducts() {
  const { products, loading } = productsService.useStore((state) => state);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [mutatingProduct, setMutatingProduct] = useState<Product>(newProduct());

  useEffect(() => {
    (async function () {
      try {
        await productsService.getProducts();
      } catch (e) {
        setError(e);
      }
    })();
  }, []);

  if (error) return <p>Error loading data: {error}</p>;

  return (
    <Box sx={{ p: 3 }}>
      <MutateProduct
        onSave={async () => {
          setModalOpen(false);
          await productsService.saveProduct({
            ...mutatingProduct,
            dataPackBytesSize: Math.floor(mutatingProduct.dataPackBytesSize)
          });
        }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        product={mutatingProduct}
        onChange={(p) => {
          setMutatingProduct(p);
        }}
      />
      <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            setMutatingProduct(newProduct());
            setModalOpen(true);
          }}
          disabled={loading}
          sx={{ mr: 2 }}
        >
          Create Product
        </Button>
      </Grid>
      <ProductTable
        products={products}
        loading={loading}
        error={error}
        onEdit={(p) => {
          setMutatingProduct(p);
          setModalOpen(true);
        }}
      />
    </Box>
  );
}

export default AdminProducts;
