/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Carrier = {
    Voxi: 'Voxi',
    Three: 'Three',
    Vodafone: 'Vodafone',
    Ee: 'EE',
    O2: 'O2'
} as const;
export type Carrier = typeof Carrier[keyof typeof Carrier];


export function instanceOfCarrier(value: any): boolean {
    for (const key in Carrier) {
        if (Object.prototype.hasOwnProperty.call(Carrier, key)) {
            if (Carrier[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CarrierFromJSON(json: any): Carrier {
    return CarrierFromJSONTyped(json, false);
}

export function CarrierFromJSONTyped(json: any, ignoreDiscriminator: boolean): Carrier {
    return json as Carrier;
}

export function CarrierToJSON(value?: Carrier | null): any {
    return value as any;
}

