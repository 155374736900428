import { ProductsApi } from 'src/api/apis/ProductsApi';
import { Product } from 'src/api/models/Product';
import { LoadingState } from '../util/LoadingState';
import { create } from 'zustand';
import { apiConfig } from '../api';

interface ProductState extends LoadingState {
  products: Product[];
  getProducts: () => Promise<Product[]>;
  saveProduct: (product: Product) => Promise<void>;
}

const useProductsStore = create<ProductState>((set) => ({
  products: [],
  loading: false,
  getProducts: async () => {
    set({ loading: true });
    try {
      const productsApi = new ProductsApi(apiConfig);
      const products = await productsApi.apiProductsGet();
      set({ products, loading: false });
      return products;
    } catch (error) {
      console.error('Failed to fetch products:', error);
      set({ loading: false });
      throw error;
    }
  },
  saveProduct: async (product: Product) => {
    set({ loading: true });
    try {
      const productsApi = new ProductsApi(apiConfig);
      await productsApi.apiProductsPost({ product });
      await useProductsStore.getState().getProducts();
      set({ loading: false });
    } catch (error) {
      console.error('Failed to save product:', error);
      set({ loading: false });
      throw error;
    }
  }
}));

class ProductsService {
  useStore = useProductsStore;
  getProducts = async () => this.useStore.getState().getProducts();
  saveProduct = async (product: Product) =>
    this.useStore.getState().saveProduct(product);
}

const productsService = new ProductsService();
export default productsService;
