import { DevicesApi } from 'src/api/apis/DevicesApi';
import { Device } from 'src/api/models/Device';
import { LoadingState } from '../util/LoadingState';
import { create } from 'zustand';
import { apiConfig } from '../api';

interface DevicesState extends LoadingState {
  devices: Device[];
  getDevices: () => Promise<Device[]>;
  rebootDevice: (deviceId: string) => Promise<Device>;
}

const useDevicesStore = create<DevicesState>((set) => ({
  devices: [],
  loading: false,
  getDevices: async () => {
    set({ loading: true });
    try {
      const devicesApi = new DevicesApi(apiConfig);
      const devices = await devicesApi.apiDevicesGet();
      set({ devices, loading: false });
      return devices;
    } catch (error) {
      console.error('Failed to fetch devices:', error);
      set({ loading: false });
      throw error;
    }
  },
  rebootDevice: async (deviceId: string) => {
    set({ loading: true });
    try {
      const devicesApi = new DevicesApi(apiConfig);
      const device = await devicesApi.apiDevicesDeviceIdRebootPost({
        deviceId
      });
      await useDevicesStore.getState().getDevices();
      set({ loading: false });
      return device;
    } catch (error) {
      console.error('Failed to reboot device:', error);
      set({ loading: false });
      throw error;
    }
  }
}));

class DevicesService {
  useStore = useDevicesStore;
  fetchDevices = async () => this.useStore.getState().getDevices();
  rebootDevice = async (deviceId: string) =>
    this.useStore.getState().rebootDevice(deviceId);
}

const devicesService = new DevicesService();
export default devicesService;
