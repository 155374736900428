import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { CenteredCircularProgress, PaddedTableCell } from 'src/styles';
import { Proxy } from 'src/api/models/Proxy';
import {
  PriorityHigh,
  SignalCellularAlt,
  SignalCellularAlt1Bar,
  SignalCellularAlt2Bar
} from '@mui/icons-material';

const signalStrengthIcon = (signalStrength: number) => {
  switch (signalStrength) {
    case 1:
      return <SignalCellularAlt1Bar htmlColor="#ff9e45" />;
    case 2:
      return <SignalCellularAlt2Bar htmlColor="#ff9e45" />;
    case 3:
    case 4:
    case 5:
      return <SignalCellularAlt color="success" />;
    default:
      return <PriorityHigh color="error" />;
  }
};

const DeviceTable = ({
  proxies,
  loading,
  error
}: {
  proxies: Proxy[];
  loading: boolean;
  error: any;
}) => {
  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer component={Paper} sx={{ boxShadow: 1 }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Public IP</TableCell>
              <TableCell>Socks5 Address</TableCell>
              <TableCell>HTTP Address</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Signal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <PaddedTableCell colSpan={10}>
                  <CenteredCircularProgress />
                </PaddedTableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography color="error">{error}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              proxies.map((proxy) => (
                <TableRow key={proxy.id}>
                  <TableCell>{proxy.username}</TableCell>
                  <TableCell>{proxy.password}</TableCell>
                  <TableCell>{proxy.publicIp}</TableCell>
                  <TableCell>{proxy.socks5Address}</TableCell>
                  <TableCell>{proxy.httpAddress}</TableCell>
                  <TableCell>{proxy.status}</TableCell>
                  <TableCell>
                    {signalStrengthIcon(proxy.signalStrength || 0)}
                  </TableCell>{' '}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DeviceTable;
