import { Suspense, lazy, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import LoginForm from './content/authentication/LoginForm';
import RegisterForm from './content/authentication/RegisterForm';
import ForgotPasswordForm from './content/authentication/ForgotPasswordForm';
import ProtectedRoute from './components/ProtectedRoute';
import RedirectIfAuthenticated from './contexts/RedirectIfAuthenticated';
import RootRedirect from './components/RootRedirect';
import Proxies from './content/dashboards/Proxies';
import DataPlans from './content/management/plans';
import Plans from './content/management/subscriptions';
import Help from './content/preferences/Help';
import Invoices from './content/preferences/Invoices';
import AccountPref from './content/preferences/AccountPref';
import Subscriptions from './content/management/subscriptions';
import AdminDevices from './content/admin/devices';
import AdminProducts from './content/admin/products';
import AdminPlans from './content/admin/plans';
import DataUsageGraph from './content/dashboards/datausage';
import MyPlans from './content/management/plans';
import PaymentCompleted from './content/management/subscriptions/PaymentComplete';
import Faq from './content/management/faq';
import AdminProxies from './content/admin/proxies';
import GuidesPage from './content/guides';
import AffiliateScreen from './content/management/affiliate';
import AffiliateRedirect from './components/AffiliateRedirect';
import NotFound from './content/util/notFound';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const routes: RouteObject[] = [
  {
    path: '/',
    element: <RootRedirect />
  },
  {
    path: 'auth',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: (
          <RedirectIfAuthenticated>
            <LoginForm />
          </RedirectIfAuthenticated>
        )
      },
      {
        path: 'register',
        element: (
          <RedirectIfAuthenticated>
            <RegisterForm />
          </RedirectIfAuthenticated>
        )
      },
      {
        path: 'reset-password',
        element: (
          <RedirectIfAuthenticated>
            <ForgotPasswordForm />
          </RedirectIfAuthenticated>
        )
      }
    ]
  },
  {
    path: 'dashboards',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'proxies',
        element: (
          <ProtectedRoute>
            <Proxies />
          </ProtectedRoute>
        )
      },
      {
        path: 'datausage',
        element: (
          <ProtectedRoute>
            <DataUsageGraph />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'management',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'affiliate',
        element: (
          <ProtectedRoute>
            <AffiliateRedirect>
              <AffiliateScreen />
            </AffiliateRedirect>
          </ProtectedRoute>
        )
      },
      {
        path: 'plans',
        element: (
          <ProtectedRoute>
            <Subscriptions />
          </ProtectedRoute>
        )
      },
      {
        path: 'faq',
        element: (
          <ProtectedRoute>
            <Faq />
          </ProtectedRoute>
        )
      },
      {
        path: 'checkout',
        element: (
          <ProtectedRoute>
            <Plans />
          </ProtectedRoute>
        )
      },
      {
        path: 'paymentcompleted',
        element: (
          <ProtectedRoute>
            <PaymentCompleted />
          </ProtectedRoute>
        )
      },
      {
        path: 'guide',
        element: (
          <ProtectedRoute>
            <GuidesPage />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'admin',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'products',
        element: (
          <ProtectedRoute>
            <AdminProducts />
          </ProtectedRoute>
        )
      },
      {
        path: 'plans',
        element: (
          <ProtectedRoute>
            <AdminPlans />
          </ProtectedRoute>
        )
      },
      {
        path: 'devices',
        element: (
          <ProtectedRoute>
            <AdminDevices />
          </ProtectedRoute>
        )
      },
      {
        path: 'proxies',
        element: (
          <ProtectedRoute>
            <AdminProxies />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: 'preferences',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'account',
        element: (
          <ProtectedRoute>
            <AccountPref />
          </ProtectedRoute>
        )
      },
      {
        path: 'invoices',
        element: (
          <ProtectedRoute>
            <Invoices />
          </ProtectedRoute>
        )
      },
      {
        path: 'help',
        element: (
          <ProtectedRoute>
            <Help />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: '*',
    element: (
      <ProtectedRoute>
        <NotFound />
      </ProtectedRoute>
    )
  }
];

export default routes;
