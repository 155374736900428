import React from 'react';
import LoadingBlock from 'src/components/LoadingBlock';
import accountService from 'src/services/AccountService';

function AccountPref() {
  const { account, loading } = accountService.useStore((state) => state);

  if (loading) return <LoadingBlock />;

  return (
    <div>
      <h1>Account Details</h1>
      {account && (
        <div>
          <p>Username: {account.email}</p>
          {/* Display other account fields as needed */}
        </div>
      )}
    </div>
  );
}

export default AccountPref;
