import React, { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import accountService from 'src/services/AccountService';

interface RedirectIfAuthenticatedProps {
  children: ReactNode;
}

const RedirectIfAuthenticated: React.FC<RedirectIfAuthenticatedProps> = ({
  children
}) => {
  const { account, loading, getCount } = accountService.useStore(
    (state) => state
  );

  useEffect(() => {
    (async () => {
      await accountService.getAccountData();
    })();
  }, []);

  if (loading || getCount <= 0) {
    return <></>;
  }

  if (account && !loading && getCount > 0) {
    return <Navigate to="/dashboards/proxies" replace />;
  }

  return <>{children}</>;
};

export default RedirectIfAuthenticated;
