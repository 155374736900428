import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import accountService from '../../../services/AccountService';

interface PasswordDialogProps {
  open: boolean;
  handleClose: (success: boolean) => void;
}

const PasswordDialog: React.FC<PasswordDialogProps> = ({
  open,
  handleClose
}) => {
  const [password, setPassword] = useState('');

  const handleSave = async () => {
    try {
      const res = await accountService.changeProxyPassword(password);
      handleClose(res !== undefined);
    } catch (error) {
      console.error('Failed to update password:', error);
      handleClose(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>Set Password for All Proxies</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the new password for all proxies.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordDialog;
