import React, { useState } from 'react';
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  Container,
  Fade,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { login } from '../../firebase-auth';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      var user = await login(email, password);
      setSnackbarSeverity('success');
      setSnackbarMessage('Login successful, redirecting...');
      setOpenSnackbar(true);
      setTimeout(() => {
        navigate('/dashboards/proxies');
      }, 2000);
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Login failed. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Fade in={true} timeout={500}>
        <Paper
          elevation={6}
          sx={{
            mt: 8,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 2
          }}
        >
          <Avatar
            src="img/icons/logo512_trans.png"
            sx={{ width: 120, height: 120, mb: 4 }}
          />
          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: 600,
              color: theme.palette.secondary.main,
              letterSpacing: 2,
              textTransform: 'uppercase',
              textDecoration: 'underline',
              textDecorationColor: theme.palette.primary.light,
              textDecorationThickness: 4,
              textUnderlineOffset: 6
            }}
          >
            Sign In
          </Typography>
          <Box
            component="form"
            onSubmit={handleLogin}
            sx={{ mt: 1, width: '100%' }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, py: 1.5 }}
              size="large"
            >
              Sign In
            </Button>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <NavLink
                to="/auth/reset-password"
                style={{ textDecoration: 'none' }}
              >
                <Button variant="text" sx={{ textTransform: 'none' }}>
                  Forgot Password?
                </Button>
              </NavLink>

              <NavLink to="/auth/register" style={{ textDecoration: 'none' }}>
                <Button
                  variant="outlined"
                  sx={{ textTransform: 'none' }}
                  size="large"
                >
                  Register
                </Button>
              </NavLink>
            </Stack>
          </Box>
        </Paper>
      </Fade>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginForm;
