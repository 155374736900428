import {
  ApiDataUsageProxyIdGetRequest,
  DatausageApi
} from 'src/api/apis/DatausageApi';
import { DataUsageRecord } from 'src/api/models/DataUsageRecord';
import proxiesService from './ProxiesService';
import { LoadingState } from '../util/LoadingState';
import { create } from 'zustand';
import { apiConfig } from '../api';

interface DataUsageState extends LoadingState {
  records: DataUsageRecord[];
  averageUsage: number;
  averageUsageLoading: boolean;
  getUsageForProxyId: (
    proxyId: string,
    from?: Date,
    to?: Date
  ) => Promise<DataUsageRecord[]>;
  getOverallUsage: (from: Date, to: Date) => Promise<DataUsageRecord[]>;
  getAverage30daysUsage: () => Promise<number>;
}

const useDataUsageStore = create<DataUsageState>((set) => ({
  records: [],
  loading: false,
  averageUsage: 0,
  averageUsageLoading: false,
  getUsageForProxyId: async (proxyId: string, from?: Date, to?: Date) => {
    set({ loading: true });
    try {
      const dataUsageApi = new DatausageApi(apiConfig);
      const request: ApiDataUsageProxyIdGetRequest = { proxyId, from, to };
      const records = (await dataUsageApi.apiDataUsageProxyIdGet(request))
        .records;
      set({ records, loading: false });
      return records;
    } catch (error) {
      console.error(`Failed to fetch data usage for proxy ${proxyId}:`, error);
      set({ loading: false });
      throw error;
    }
  },
  getOverallUsage: async (from: Date, to: Date) => {
    set({ loading: true });
    try {
      const proxies = await proxiesService.useStore.getState().fetchProxies();
      const usagePromises = proxies.map((proxy) =>
        useDataUsageStore.getState().getUsageForProxyId(proxy.id, from, to)
      );
      await Promise.all(usagePromises);
      const dataUsageApi = new DatausageApi(apiConfig);
      const records = (await dataUsageApi.apiDataUsageGet({ from, to }))
        .records;
      set({ records, loading: false });
      return records;
    } catch (error) {
      console.error('Failed to fetch overall data usage:', error);
      set({ loading: false });
      throw error;
    }
  },
  getAverage30daysUsage: async () => {
    set({ averageUsageLoading: true });
    try {
      const last30DaysRecords = await useDataUsageStore
        .getState()
        .getOverallUsage(new Date(-30), new Date());
      const totalUsed = last30DaysRecords.reduce(
        (acc, record) => acc + record.uploaded + record.downloaded,
        0
      );
      const average = totalUsed / 30;
      set({ averageUsage: average, averageUsageLoading: false });
      return average;
    } catch (error) {
      console.error('Failed to fetch average 30 days usage:', error);
      set({ averageUsageLoading: false });
      throw error;
    }
  }
}));

class DataUsageService {
  useStore = useDataUsageStore;
  getUsageForProxyId = async (proxyId: string, from?: Date, to?: Date) => {
    await this.useStore.getState().getUsageForProxyId(proxyId, from, to);
  };
  getOverallUsage = async (from: Date, to: Date) =>
    this.useStore.getState().getOverallUsage(from, to);
  getAverage30daysUsage = async () =>
    this.useStore.getState().getAverage30daysUsage();
}

const dataUsageService = new DataUsageService();
export default dataUsageService;
