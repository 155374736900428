import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import PlanTable from './PlanTable';
import plansService from 'src/services/PlansService';
import MutatePlan from './MutatePlan';
import { Plan } from 'src/api/models/Plan';
import { v4 as uuidv4 } from 'uuid';

function newPlan(): Plan {
  return {
    id: uuidv4(),
    name: '',
    description: '',
    stripeId: '',
    stripePriceId: '',
    carriers: ['Voxi'],
    monthlyCostCents: 0,
    allowedProxyCount: 0,
    maxSpeedKbs: 5196,
    initialFreeDataMB: 0,
    active: false
  };
}

function AdminPlans() {
  const { plans, loading } = plansService.useStore((state) => state);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [mutatingPlan, setMutatingPlan] = useState<Plan>(newPlan());

  useEffect(() => {
    (async function () {
      try {
        await plansService.getPlans();
      } catch (e) {
        setError(e);
      }
    })();
  }, []);

  if (error) return <p>Error loading data: {error}</p>;

  return (
    <Box sx={{ p: 3 }}>
      <MutatePlan
        onSave={async () => {
          setModalOpen(false);
          await plansService.savePlan(mutatingPlan);
        }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        plan={mutatingPlan}
        onChange={(p) => {
          setMutatingPlan(p);
        }}
      />
      <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            setMutatingPlan(newPlan());
            setModalOpen(true);
          }}
          disabled={loading}
          sx={{ mr: 2 }}
        >
          Create Plan
        </Button>
      </Grid>
      <PlanTable
        plans={plans}
        loading={loading}
        error={error}
        onEdit={(p) => {
          setMutatingPlan(p);
          setModalOpen(true);
        }}
      />
    </Box>
  );
}

export default AdminPlans;
