import { styled } from '@mui/material/styles';
import { Box, IconButton, TableCell } from '@mui/material';

export const StyledBox = styled(Box)({
  width: '100%',
  overflowX: 'auto'
});

export const StyledTableCell = styled(TableCell)({
  paddingLeft: 24
});

export const StyledIconButton = styled(IconButton)({
  marginLeft: 5
});
