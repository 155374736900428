import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Container,
  Fade,
  Typography
} from '@mui/material';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PaymentCompleted = () => {
  const query = useQuery();
  const sessionId = query.get('session_id');
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const onClickHandler = () => {
    setShow(!show);
  };

  useEffect(() => {
    setError(true);
    setShow(true);
    const timer = setTimeout(() => {
      setError(false);
      setLoading(false);
    }, 2000);
    // const purchase = CheckoutService.createCheckoutSession()
    // setTransaction()
    return () => clearTimeout(timer);
  }, [sessionId]);
  const fadeRef = useRef(null);

  if (loading) {
    return (
      <Fade in={show} ref={fadeRef}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Processing your transaction...
          </Typography>
        </Container>
      </Fade>
    );
  }

  if (error || !transaction?.success) {
    return (
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* <Typography variant="h6">Payment Status: {error}</Typography> */}
        <Typography variant="body1">
          Your transaction is still being processed. Please check back later or
          contact support if you have not received your order within the next 15
          minutes.
        </Typography>
        <Button variant="contained" sx={{ mt: 3 }} href="/">
          Check Transaction Status
        </Button>
      </Container>
    );
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        mt: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Typography variant="h6">Payment Successful!</Typography>
      <Typography variant="body1">
        Thank you for your purchase. Your transaction was successful.
        Transaction ID: {transaction.transactionId}
      </Typography>
      <Button variant="contained" sx={{ mt: 3 }} href="/">
        Go to Dashboard
      </Button>
    </Container>
  );
};

export default PaymentCompleted;
