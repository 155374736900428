import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip
} from '@mui/material';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { StyledButton, StyledTypography } from './styles';

function IpChangeWarning() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title="Changes made to IP addresses" arrow>
        <StyledButton onClick={handleClick} startIcon={<WarningTwoToneIcon />}>
          <StyledTypography variant="body1">IP Changes</StyledTypography>
        </StyledButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'IP Changes'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Due to ongoing improvements and updates, the IP address you use to
            connect to your proxy has changed. Please update your configurations
            accordingly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default IpChangeWarning;
