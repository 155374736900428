import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import Guide from 'src/components/Guide';

const guides = [
  {
    title: 'HTTP Guide',
    url: '/guides/HTTP_guide.md'
  },
  {
    title: 'SOCKS5 Guide',
    url: '/guides/SOCKS5_guide.md'
  }
];

const GuidesPage = () => {
  const [selectedGuide, setSelectedGuide] = React.useState(null);

  return (
    <Box sx={{ display: 'flex', padding: 2 }}>
      <Box sx={{ width: '30%', borderRight: '1px solid #ddd', padding: 2 }}>
        <Typography variant="h6">Guides</Typography>
        <List>
          {guides.map((guide, index) => (
            <ListItem
              button
              key={index}
              onClick={() => setSelectedGuide(guide.url)}
            >
              <ListItemText primary={guide.title} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box sx={{ width: '70%', padding: 2 }}>
        {selectedGuide ? (
          <Guide url={selectedGuide} />
        ) : (
          <Typography variant="body1">Select a guide to read</Typography>
        )}
      </Box>
    </Box>
  );
};

export default GuidesPage;
