/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AffiliatePayout } from './AffiliatePayout';
import {
    AffiliatePayoutFromJSON,
    AffiliatePayoutFromJSONTyped,
    AffiliatePayoutToJSON,
} from './AffiliatePayout';

/**
 * Contains information related to the affiliate.
 * @export
 * @interface AffiliateResponse
 */
export interface AffiliateResponse {
    /**
     * 
     * @type {number}
     * @memberof AffiliateResponse
     */
    balance: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliateResponse
     */
    clickCount: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliateResponse
     */
    purchaseCount: number;
    /**
     * 
     * @type {number}
     * @memberof AffiliateResponse
     */
    registerCount: number;
    /**
     * 
     * @type {Array<AffiliatePayout>}
     * @memberof AffiliateResponse
     */
    payouts: Array<AffiliatePayout>;
}

/**
 * Check if a given object implements the AffiliateResponse interface.
 */
export function instanceOfAffiliateResponse(value: object): value is AffiliateResponse {
    if (!('balance' in value) || value['balance'] === undefined) return false;
    if (!('clickCount' in value) || value['clickCount'] === undefined) return false;
    if (!('purchaseCount' in value) || value['purchaseCount'] === undefined) return false;
    if (!('registerCount' in value) || value['registerCount'] === undefined) return false;
    if (!('payouts' in value) || value['payouts'] === undefined) return false;
    return true;
}

export function AffiliateResponseFromJSON(json: any): AffiliateResponse {
    return AffiliateResponseFromJSONTyped(json, false);
}

export function AffiliateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffiliateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'balance': json['balance'],
        'clickCount': json['clickCount'],
        'purchaseCount': json['purchaseCount'],
        'registerCount': json['registerCount'],
        'payouts': ((json['payouts'] as Array<any>).map(AffiliatePayoutFromJSON)),
    };
}

export function AffiliateResponseToJSON(value?: AffiliateResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'balance': value['balance'],
        'clickCount': value['clickCount'],
        'purchaseCount': value['purchaseCount'],
        'registerCount': value['registerCount'],
        'payouts': ((value['payouts'] as Array<any>).map(AffiliatePayoutToJSON)),
    };
}

