/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubscriptionStatus } from './SubscriptionStatus';
import {
    SubscriptionStatusFromJSON,
    SubscriptionStatusFromJSONTyped,
    SubscriptionStatusToJSON,
} from './SubscriptionStatus';
import type { Plan } from './Plan';
import {
    PlanFromJSON,
    PlanFromJSONTyped,
    PlanToJSON,
} from './Plan';

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    expiresAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    lastRenewedAt: Date;
    /**
     * 
     * @type {Plan}
     * @memberof Subscription
     */
    plan: Plan;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof Subscription
     */
    status: SubscriptionStatus;
    /**
     * 
     * @type {Date}
     * @memberof Subscription
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Subscription interface.
 */
export function instanceOfSubscription(value: object): value is Subscription {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('expiresAt' in value) || value['expiresAt'] === undefined) return false;
    if (!('lastRenewedAt' in value) || value['lastRenewedAt'] === undefined) return false;
    if (!('plan' in value) || value['plan'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'expiresAt': (new Date(json['expiresAt'])),
        'lastRenewedAt': (new Date(json['lastRenewedAt'])),
        'plan': PlanFromJSON(json['plan']),
        'status': SubscriptionStatusFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'expiresAt': ((value['expiresAt']).toISOString()),
        'lastRenewedAt': ((value['lastRenewedAt']).toISOString()),
        'plan': PlanToJSON(value['plan']),
        'status': SubscriptionStatusToJSON(value['status']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

