/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeSubscriptionPlanResponse
 */
export interface ChangeSubscriptionPlanResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeSubscriptionPlanResponse
     */
    requiresPayment: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeSubscriptionPlanResponse
     */
    key?: string;
}

/**
 * Check if a given object implements the ChangeSubscriptionPlanResponse interface.
 */
export function instanceOfChangeSubscriptionPlanResponse(value: object): value is ChangeSubscriptionPlanResponse {
    if (!('requiresPayment' in value) || value['requiresPayment'] === undefined) return false;
    return true;
}

export function ChangeSubscriptionPlanResponseFromJSON(json: any): ChangeSubscriptionPlanResponse {
    return ChangeSubscriptionPlanResponseFromJSONTyped(json, false);
}

export function ChangeSubscriptionPlanResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeSubscriptionPlanResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'requiresPayment': json['requiresPayment'],
        'key': json['key'] == null ? undefined : json['key'],
    };
}

export function ChangeSubscriptionPlanResponseToJSON(value?: ChangeSubscriptionPlanResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requiresPayment': value['requiresPayment'],
        'key': value['key'],
    };
}

