import { useMemo } from 'react';
import {
  Box,
  LinearProgress,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import accountService from 'src/services/AccountService';

const shortenBytes = (bytes) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let i = 0;
  while (bytes >= 1024 && i < units.length - 1) {
    bytes /= 1024;
    i++;
  }
  return `${bytes.toFixed(2)} ${units[i]}`;
};

const DataPackIndicatorWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.spacing(2)};
    box-shadow: ${theme.shadows[1]};
    margin: ${theme.spacing(2, 0)};
    color: ${theme.palette.common.white};
`
);

const DataPackProgress = styled(LinearProgress)(
  ({ theme }) => `
    width: 100%;
    margin-top: ${theme.spacing(1)};
    &.MuiLinearProgress-colorPrimary {
      background-color: ${theme.palette.grey[700]};
    }
    .MuiLinearProgress-bar {
      background-color: ${theme.palette.info.main};
    }
`
);

const DataPackIndicator = () => {
  const theme = useTheme();
  const { account, loading, getCount } = accountService.useStore(
    (state) => state
  );
  const totalRemaining = useMemo(
    () => (account ? accountService.getTotalDataRemaining() : 0),
    [account]
  );
  const totalAllocated = useMemo(
    () => (account ? accountService.getTotalDataAllocated() : 0),
    [account]
  );

  if (getCount <= 0 || loading) return null;

  const progress = (totalRemaining / totalAllocated) * 100;

  if (totalRemaining <= 0) {
    return <DataPackIndicatorWrapper></DataPackIndicatorWrapper>;
  }

  return (
    <DataPackIndicatorWrapper>
      <Typography variant="h6" sx={{ color: theme.palette.info.dark }}>
        Data Remaining
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: theme.palette.common.white, fontWeight: 'bold' }}
      >
        {shortenBytes(totalRemaining)}{' '}
        <span style={{ color: theme.palette.grey[500] }}>out of</span>{' '}
        {shortenBytes(totalAllocated)}
      </Typography>
      <DataPackProgress variant="determinate" value={Math.min(progress, 100)} />
    </DataPackIndicatorWrapper>
  );
};

export default DataPackIndicator;
