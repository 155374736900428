import { SubscriptionsApi } from 'src/api/apis/SubscriptionsApi';
import {
  Subscription,
  ChangeSubscriptionPlanRequest,
  ChangeSubscriptionPlanResponse
} from 'src/api/models';
import { LoadingState } from '../util/LoadingState';
import { create } from 'zustand';
import { apiConfig } from '../api';

interface SubscriptionState extends LoadingState {
  subscriptions: Subscription[];
  getAllSubscriptions: () => Promise<Subscription[]>;
  changeSubscription: (
    subId: string,
    changeRequest: ChangeSubscriptionPlanRequest
  ) => Promise<ChangeSubscriptionPlanResponse>;
  deleteSubscription: (subId: string) => Promise<Subscription>;
  getSubscriptionById: (subId: string) => Promise<Subscription>;
}

const useSubscriptionStore = create<SubscriptionState>((set) => ({
  subscriptions: [],
  loading: false,
  getAllSubscriptions: async () => {
    set({ loading: true });
    try {
      const subscriptionsApi = new SubscriptionsApi(apiConfig);
      const subscriptions = await subscriptionsApi.apiSubscriptionsGet();
      set({ subscriptions, loading: false });
      return subscriptions;
    } catch (error) {
      console.error('Failed to get subscriptions:', error);
      set({ loading: false });
      throw error;
    }
  },
  changeSubscription: async (
    subId: string,
    changeRequest: ChangeSubscriptionPlanRequest
  ) => {
    set({ loading: true });
    try {
      const subscriptionsApi = new SubscriptionsApi(apiConfig);
      const response = await subscriptionsApi.apiSubscriptionsSubIdChangePost({
        subId,
        changeSubscriptionPlanRequest: changeRequest
      });
      await useSubscriptionStore.getState().getAllSubscriptions();
      set({ loading: false });
      return response;
    } catch (error) {
      console.error('Failed to update subscription:', error);
      set({ loading: false });
      throw error;
    }
  },
  deleteSubscription: async (subId: string) => {
    set({ loading: true });
    try {
      const subscriptionsApi = new SubscriptionsApi(apiConfig);
      const response = await subscriptionsApi.apiSubscriptionsSubIdDelete({
        subId
      });
      await useSubscriptionStore.getState().getAllSubscriptions();
      set({ loading: false });
      return response;
    } catch (error) {
      console.error('Failed to delete subscription:', error);
      set({ loading: false });
      throw error;
    }
  },
  getSubscriptionById: async (subId: string) => {
    set({ loading: true });
    try {
      const subscriptionsApi = new SubscriptionsApi(apiConfig);
      const subscription = await subscriptionsApi.apiSubscriptionsSubIdGet({
        subId
      });
      set({ loading: false });
      return subscription;
    } catch (error) {
      console.error('Failed to get subscription by ID:', error);
      set({ loading: false });
      throw error;
    }
  }
}));

class SubscriptionsService {
  useStore = useSubscriptionStore;
  getAllSubscriptions = async () =>
    this.useStore.getState().getAllSubscriptions();
  changeSubscription = async (
    subId: string,
    changeRequest: ChangeSubscriptionPlanRequest
  ) => this.useStore.getState().changeSubscription(subId, changeRequest);
  deleteSubscription = async (subId: string) => this.deleteSubscription(subId);
  getSubscriptionById = async (subId: string) =>
    this.useStore.getState().getSubscriptionById(subId);
}

const subscriptionsService = new SubscriptionsService();
export default subscriptionsService;
