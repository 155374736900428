import { Link } from '@mui/material';
import { useMemo } from 'react';
import accountService from 'src/services/AccountService';
import { ErrorAlertBox, NoUnderlineLink, WarningAlertBox } from './styles';
import { shortenBytes } from 'src/util/data';

const NoWarningThreshold = 100 * 1024 * 1024;

const DataPackWarning = () => {
  const { account, loading, getCount } = accountService.useStore(
    (state) => state
  );
  const totalRemaining = useMemo(
    () => (account ? accountService.getTotalDataRemaining() : 0),
    [account]
  );

  if (location.pathname.split('/')[1] == 'management') return <></>;

  if (getCount <= 0 || loading || totalRemaining > NoWarningThreshold)
    return <></>;

  if (totalRemaining <= 0) {
    return (
      <>
        <NoUnderlineLink href="/management/plans">
          <ErrorAlertBox variant="filled" severity="error">
            You have no data remaining, click{' '}
            <Link href="/management/plans">here</Link> to buy more and avoid
            disruption.
          </ErrorAlertBox>
        </NoUnderlineLink>
      </>
    );
  }

  return (
    <>
      <NoUnderlineLink href="/management/plans">
        <WarningAlertBox variant="filled" severity="warning">
          You only have {shortenBytes(totalRemaining)} data remaining, click{' '}
          <Link href="/management/plans">here</Link> to buy more and avoid
          disruption.
        </WarningAlertBox>
      </NoUnderlineLink>
    </>
  );
};

export default DataPackWarning;
