import { Link } from '@mui/material';
import accountService from 'src/services/AccountService';
import { AlertBox, NoUnderlineLink } from './styles';

const PlanWarning = () => {
  const { account, loading, getCount } = accountService.useStore(
    (state) => state
  );

  if (location.pathname.split('/')[1] == 'management') return <></>;

  if (getCount <= 0 || loading || account?.plan != null) return <></>;

  return (
    <>
      <NoUnderlineLink href="/management/plans">
        <AlertBox variant="filled" severity="error">
          You have no active plan, click{' '}
          <Link href="/management/plans">here</Link> to purchase a plan.
        </AlertBox>
      </NoUnderlineLink>
    </>
  );
};

export default PlanWarning;
