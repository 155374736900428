import React, { useState } from 'react';
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  Container,
  Fade,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { resetPassword } from '../../firebase-auth';

const ResetPasswordForm = () => {
  const [email, setEmail] = useState('');
  const theme = useTheme();
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('error');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await resetPassword(email);
      setSnackbarSeverity('success');
      setSnackbarMessage('Password email sent! Check your inbox.');
      setOpenSnackbar(true);
      await delay(5000);
      setTimeout(() => {
        navigate('/');
      }, 5000);
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage(
        'Could not find an account attached to the email provied.'
      );
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Fade in={true} timeout={500}>
        <div>
          <Button
            startIcon={<ArrowBackIcon />}
            sx={{ alignSelf: 'flex-start', marginTop: 2 }}
            onClick={() => navigate('/auth')}
          >
            Back
          </Button>
          <Paper
            elevation={6}
            sx={{
              mt: 1,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 2
            }}
          >
            <Avatar
              src="img/icons/logo512_trans.png"
              sx={{ width: 120, height: 120, mb: 4 }}
            />
            <Typography
              component="h1"
              variant="h4"
              sx={{
                mb: 3,
                fontWeight: 600,
                color: theme.palette.secondary.main,
                letterSpacing: 2,
                textTransform: 'uppercase',
                textDecoration: 'underline',
                textDecorationColor: theme.palette.primary.light,
                textDecorationThickness: 4,
                textUnderlineOffset: 6
              }}
            >
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Link
              </Button>
            </Box>
          </Paper>
        </div>
      </Fade>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ResetPasswordForm;
