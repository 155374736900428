import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Modal,
  Typography
} from '@mui/material';
import plansService from '../../../services/PlansService';
import subscriptionsService from '../../../services/SubscriptionsService';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StyledModalBox } from './styles';

const UpgradeModal = ({ open, handleClose, currentPlan }) => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [awaitingPayment, setAwaitingPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (open) {
      fetchPlans();
    }
  }, [open, currentPlan.id]);

  useEffect(() => {
    const fetchStripeConfig = async () => {
      try {
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_KEY
        );
        setStripePromise(stripe);
        console.log('Stripe loaded successfully.');
      } catch (error) {
        console.error('Failed to load Stripe:', error);
      }
    };
    fetchStripeConfig();
  }, []);

  const fetchPlans = async () => {
    setLoading(true);
    try {
      const fetchedPlans = await plansService.getPlans();
      setPlans(fetchedPlans.filter((plan) => plan.id !== currentPlan.id));
    } catch (error) {
      console.error('Failed to fetch plans:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlanSelection = async (newPlan) => {
    setLoading(true);
    try {
      const changeRequest = {
        newPlanId: newPlan.id
      };

      const mySubscription = await subscriptionsService.getAllSubscriptions();
      const response = await subscriptionsService.changeSubscription(
        mySubscription[0].id,
        changeRequest
      );

      if (response.requiresPayment) {
        console.log('Requires payment:', response.key);
        setClientSecret(response.key.toString());
        setAwaitingPayment(true);
      } else {
        alert(
          'Your plan will change from ' +
            currentPlan.name +
            ' to ' +
            newPlan.name +
            ' after the next billing cycle.'
        );
        handleClose();
      }
    } catch (error) {
      console.error('Failed to change the plan:', error);
      alert('Failed to change the plan. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setAwaitingPayment(false);
        setLoading(false);
        setClientSecret(null);
        setStripePromise(null);
        handleClose();
      }}
      aria-labelledby="subscription-modal-title"
      aria-describedby="subscription-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80%',
        overflowY: 'auto'
      }}
    >
      {awaitingPayment ? (
        <StyledModalBox>
          {!clientSecret || !stripePromise ? (
            <CircularProgress />
          ) : (
            <Box sx={{ width: '100%', maxWidth: 400 }}>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{ clientSecret }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </Box>
          )}
        </StyledModalBox>
      ) : (
        <Box
          sx={{
            width: '80%',
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2
          }}
        >
          <Typography id="subscription-modal-title" variant="h6" gutterBottom>
            Choose a Subscription Plan
          </Typography>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={3}>
              {plans.map((plan) => (
                <Grid item xs={12} sm={6} md={4} key={plan.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5">{plan.name}</Typography>
                      <Typography variant="body2">
                        ${plan.monthlyCostCents / 100} / month
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => handlePlanSelection(plan)}
                      >
                        Choose Plan
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </Modal>
  );
};

export default UpgradeModal;
