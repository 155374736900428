import { Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1]
}));

export const DateInputContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(2)
}));

export const ChartContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1]
}));

export const TileContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1]
}));

export const LoadingIndicatorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(5),
  width: '100%'
}));
