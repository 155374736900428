import React from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Plan } from 'src/api/models/Plan';
import { CenteredCircularProgress, PaddedTableCell } from 'src/styles';

const PlanTable = ({
  plans,
  loading,
  error,
  onEdit
}: {
  plans: Plan[];
  loading: boolean;
  error: any;
  onEdit: (Plan) => void;
}) => {
  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer component={Paper} sx={{ boxShadow: 1 }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Initial Free Data</TableCell>
              <TableCell>Monthly Cost</TableCell>
              <TableCell>Stripe ID</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <PaddedTableCell colSpan={5}>
                  <CenteredCircularProgress />
                </PaddedTableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography color="error">{error}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              plans.map((plan) => (
                <TableRow key={plan.id}>
                  <TableCell>{plan.name}</TableCell>
                  <TableCell>{plan.description}</TableCell>
                  <TableCell>{plan.initialFreeDataMB}</TableCell>
                  <TableCell>${plan.monthlyCostCents / 100}</TableCell>
                  <TableCell>{plan.stripeId}</TableCell>
                  <TableCell>
                    <Grid container paddingLeft="10px" alignItems="center">
                      <Grid item>
                        {plan.active ? (
                          <CheckCircleOutlineIcon color="success" />
                        ) : (
                          <HighlightOffIcon color="error" />
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => onEdit(plan)}>Edit</Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PlanTable;
