import React from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Product } from 'src/api/models/Product';
import { CenteredCircularProgress, PaddedTableCell } from 'src/styles';
import { shortenBytes } from 'src/util/data';

const ProductTable = ({
  products,
  loading,
  error,
  onEdit
}: {
  products: Product[];
  loading: boolean;
  error: any;
  onEdit: (Product) => void;
}) => {
  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer component={Paper} sx={{ boxShadow: 1 }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Stripe Id</TableCell>
              <TableCell>Data Pack Size</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <PaddedTableCell colSpan={7}>
                  <CenteredCircularProgress />
                </PaddedTableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography color="error">{error}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.description}</TableCell>
                  <TableCell>${product.costCents / 100}</TableCell>
                  <TableCell>{product.productType}</TableCell>
                  <TableCell>{product.stripeId}</TableCell>
                  <TableCell>
                    {shortenBytes(product.dataPackBytesSize)}
                  </TableCell>
                  <TableCell>
                    <Grid container paddingLeft="10px" alignItems="center">
                      <Grid item>
                        {product.active ? (
                          <CheckCircleOutlineIcon color="success" />
                        ) : (
                          <HighlightOffIcon color="error" />
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => onEdit(product)}>Edit</Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductTable;
