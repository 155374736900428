import {
  Button,
  FormControlLabel,
  Modal,
  Switch,
  TextField
} from '@mui/material';
import { Plan } from 'src/api/models/Plan';
import { ModalBox } from './styles';

const MutatePlan = ({
  plan,
  onClose,
  open,
  onChange,
  onSave
}: {
  plan: Plan;
  onClose: () => void;
  open: boolean;
  onChange: (Plan) => void;
  onSave: () => void;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBox>
        <TextField
          label="Name"
          variant="outlined"
          value={plan.name}
          onChange={(e) => {
            onChange({
              ...plan,
              name: e.target.value
            });
          }}
        />
        <TextField
          label="Description"
          variant="outlined"
          value={plan.description}
          onChange={(e) => {
            onChange({
              ...plan,
              description: e.target.value
            });
          }}
        />
        <TextField
          type="number"
          label="Monthly Cost In Cents"
          variant="outlined"
          value={plan.monthlyCostCents}
          onChange={(e) => {
            onChange({
              ...plan,
              monthlyCostCents: Number(e.target.value)
            });
          }}
        />
        <TextField
          type="number"
          label="Proxy Count"
          variant="outlined"
          value={plan.allowedProxyCount}
          onChange={(e) => {
            onChange({
              ...plan,
              allowedProxyCount: Number(e.target.value)
            });
          }}
        />
        <TextField
          type="number"
          label="Initial Free Data MB"
          variant="outlined"
          value={plan.initialFreeDataMB}
          onChange={(e) => {
            onChange({
              ...plan,
              initialFreeDataMB: Number(e.target.value)
            });
          }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={plan.active}
              onChange={(e) => {
                onChange({
                  ...plan,
                  active: e.target.checked
                });
              }}
            />
          }
          label="Active"
        />
        <Button onClick={onSave} variant="contained">
          Save
        </Button>
      </ModalBox>
    </Modal>
  );
};

export default MutatePlan;
