import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import productsService from '../../../services/ProductsService';
import ProductTile from './ProductTile';
import Basket, { BasketItem } from './Basket';
import { Product } from 'src/api/models/Product';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import checkoutService from '../../../services/CheckoutService';
import { Plan } from 'src/api/models/Plan';
import plansService from '../../../services/PlansService';
import { CloseOutlined } from '@mui/icons-material';
import accountService from '../../../services/AccountService';
import UpgradeModal from './UpgradeModal';
import { auth } from '../../../firebase-auth';
import ThemeProvider from '../../../theme/ThemeProvider';
import { GlobalStyle } from '../../../styles';

const Subscriptions = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [stripePromise, setStripePromise] = useState(null);
  const [basket, setBasket] = useState<Map<string, BasketItem>>(new Map());
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [existingSubscription, setExistingSubscription] = useState<
    Plan | undefined
  >();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    const fetchStripeConfig = async () => {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      setStripePromise(stripe);
    };
    fetchStripeConfig();
  }, []);

  useEffect(() => {
    const fetchProductsAndPlans = async () => {
      setLoading(true);
      try {
        const fetchedProducts = await productsService.getProducts();
        const fetchedPlans = await plansService.getPlans();
        setProducts(fetchedProducts.filter((product) => product.active));
        const account = await accountService.getAccountData();
        if (account && account.plan) {
          setExistingSubscription(account.plan);
          setPlans(fetchedPlans.filter((plan) => plan.id !== account.plan.id));
        } else {
          setPlans(fetchedPlans.filter((plan) => plan.active));
        }
      } catch (error) {
        console.error('Failed to fetch products and plans:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchProductsAndPlans();
  }, []);

  const isAnyItemASubscription = useCallback(() => {
    return Array.from(basket.values()).some((item) => item.plan);
  }, [basket]);

  const isAnyItemADataPlan = useCallback(() => {
    return Array.from(basket.values()).some((item) => item.product);
  }, [basket]);

  const handleCheckout = useCallback(async () => {
    if (!isAnyItemASubscription()) {
      const account = await accountService.getAccountData();
      if (!account || !account.plan) {
        setError(
          'You require an active subscription to continue. Please add a subscription to your cart.'
        );
        return;
      }
    }

    let finalProducts = new Map();
    basket.forEach((item, key) => {
      if (item.product) {
        finalProducts.set(item.product.id.toString(), item.quantity);
      }
    });
    try {
      setLoadingPayment(true);
      const session = await checkoutService.createCheckoutSession(
        finalProducts,
        existingSubscription
          ? ''
          : Array.from(basket.values())
              .find((item) => item.plan)
              ?.plan.id.toString()
      );
      setClientSecret(session);
    } catch (error) {
      setLoadingPayment(false);
      console.error('Failed to initiate checkout:', error);
    }
  }, [basket, existingSubscription]);

  const handleAddProductItem = useCallback(
    (product: Product) => {
      if (product.subscription && isAnyItemASubscription()) {
        setError('You can only have one subscription at a time.');
        return;
      }
      setBasket((prev) => {
        const existingEntry = prev.get(product.id);
        const newQuantity = existingEntry ? existingEntry.quantity + 1 : 1;
        return new Map(prev).set(product.id, {
          product,
          quantity: newQuantity
        });
      });
      setError('');
    },
    [isAnyItemASubscription]
  );

  const handleAddPlanItem = useCallback(
    (plan: Plan) => {
      if (isAnyItemASubscription()) {
        setError('You can only have one subscription at a time.');
        return;
      }
      setBasket((prev) => {
        const existingEntry = prev.get(plan.id);
        const newQuantity = existingEntry ? existingEntry.quantity + 1 : 1;
        return new Map(prev).set(plan.id, { plan, quantity: newQuantity });
      });
      setError('');
    },
    [isAnyItemASubscription]
  );

  const updateBasket = useCallback((newBasket: Map<string, BasketItem>) => {
    setBasket(newBasket);
    setError('');
  }, []);

  return (
    <ThemeProvider>
      <Container
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          p: 2,
          maxWidth: 'lg'
        }}
      >
        <Box sx={{ flexGrow: 1, pr: { md: 4 }, width: '100%' }}>
          <Collapse in={error !== ''}>
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setError('')}
                >
                  <CloseOutlined fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          </Collapse>
          {existingSubscription ? (
            <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
              <Typography variant="h5" gutterBottom>
                Your Current Subscription Plan
              </Typography>
              <Typography variant="subtitle1">
                {existingSubscription.name}
              </Typography>
              <Typography variant="body2">
                Monthly Cost: ${existingSubscription.monthlyCostCents / 100}
              </Typography>
              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() => {
                  window.location.replace(
                    `https://billing.stripe.com/p/login/aEU6s38Dw3TB4Hm000?prefilled_email=${auth.currentUser?.email}`
                  );
                }}
              >
                Manage payments and subscriptions
              </Button>
            </Paper>
          ) : (
            <>
              <Typography variant="h4" gutterBottom>
                Subscription Products
              </Typography>
              <Grid container spacing={3}>
                {loading ? (
                  <Typography>Loading...</Typography>
                ) : (
                  plans.map((plan) => {
                    const product = {
                      name: plan.name,
                      costCents: plan.monthlyCostCents,
                      subscription: true,
                      stripePriceId: plan.stripePriceId,
                      id: plan.id.toString(),
                      freeData: plan.initialFreeDataMB.toString()
                    };
                    return (
                      <Grid item xs={12} sm={6} md={4} key={plan.id}>
                        <ProductTile
                          product={product}
                          addToBasket={() => handleAddPlanItem(plan)}
                        />
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </>
          )}
          <Divider sx={{ my: 4 }} />
          <Typography variant="h4" gutterBottom>
            Data packs
          </Typography>
          <Grid container spacing={3}>
            {loading ? (
              <Typography>Loading...</Typography>
            ) : (
              products.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.id}>
                  <ProductTile
                    product={{
                      name: product.name,
                      costCents: product.costCents,
                      subscription: false,
                      stripePriceId: product.stripePriceId,
                      id: product.id.toString(),
                      freeData: null
                    }}
                    addToBasket={() => handleAddProductItem(product)}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Box sx={{ width: { xs: '100%', md: 300 }, mt: { xs: 2, md: 0 } }}>
          <Basket
            basket={basket}
            onCheckout={handleCheckout}
            updateBasket={updateBasket}
          />
        </Box>
        {existingSubscription && (
          <Box style={{ overflowY: 'visible' }}>
            <UpgradeModal
              open={showUpgradeModal}
              handleClose={() => setShowUpgradeModal(false)}
              currentPlan={existingSubscription}
            />
          </Box>
        )}

        {loadingPayment && (
          <Modal
            open={loadingPayment}
            onClose={() => {
              setClientSecret(null);
              setLoadingPayment(false);
            }}
            aria-labelledby="stripe-checkout-modal"
            aria-describedby="stripe-checkout-modal-description"
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
              style: { backdropFilter: 'blur(3px)' }
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: clientSecret ? '95%' : 'auto',
                width: clientSecret ? '100%' : 'auto',
                maxHeight: '100vh',
                overflow: clientSecret ? 'auto' : 'hidden',
                padding: 2,
                transition: 'height 0.5s ease-in-out, width 0.5s ease-in-out'
              }}
            >
              {!clientSecret && <CircularProgress />}
              {clientSecret && (
                <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ clientSecret }}
                  >
                    <GlobalStyle />
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                </Box>
              )}
            </Box>
          </Modal>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Subscriptions;
