import { getAuth } from 'firebase/auth';
import { Configuration } from './api/runtime';

const getFirebaseToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? user.getIdToken() : '';
};

export const apiConfig = new Configuration({
  apiKey: async (name: string) => {
    if (name === 'Authorization') {
      const token = await getFirebaseToken();
      return `Bearer ${token}`;
    }
    return '';
  },
  accessToken: async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.getIdToken() : '';
  },
  basePath: process.env.REACT_APP_BASE_PATH
});
