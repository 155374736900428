import React from 'react';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Device } from 'src/api/models/Device';
import devicesService from 'src/services/DevicesService';
import {
  PriorityHigh,
  SignalCellularAlt,
  SignalCellularAlt1Bar,
  SignalCellularAlt2Bar
} from '@mui/icons-material';
import { CenteredCircularProgress, PaddedTableCell } from 'src/styles';

const signalStrengthIcon = (signalStrength: number) => {
  switch (signalStrength) {
    case 1:
      return <SignalCellularAlt1Bar htmlColor="#ff9e45" />;
    case 2:
      return <SignalCellularAlt2Bar htmlColor="#ff9e45" />;
    case 3:
    case 4:
    case 5:
      return <SignalCellularAlt color="success" />;
    default:
      return <PriorityHigh color="error" />;
  }
};
const DeviceTable = ({
  devices,
  loading,
  error
}: {
  devices: Device[];
  loading: boolean;
  error: any;
}) => {
  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TableContainer component={Paper} sx={{ boxShadow: 1 }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>Public IP</TableCell>
              <TableCell>Private IP</TableCell>
              <TableCell>Signal</TableCell>
              <TableCell>Network</TableCell>
              <TableCell>Download</TableCell>
              <TableCell>Upload</TableCell>
              <TableCell>Node IP</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Online At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <PaddedTableCell colSpan={10}>
                  <CenteredCircularProgress />
                </PaddedTableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography color="error">{error}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              devices.map((device) => (
                <TableRow key={device.id}>
                  <TableCell>{device.publicIp}</TableCell>
                  <TableCell>{device.privateIp}</TableCell>
                  <TableCell>
                    {signalStrengthIcon(device.signalStrength || 0)}
                  </TableCell>
                  <TableCell>{device.networkName}</TableCell>
                  <TableCell>{device.downloadSpeed}</TableCell>
                  <TableCell>{device.uploadSpeed}</TableCell>
                  <TableCell>{device.nodeIp}</TableCell>
                  <TableCell>
                    <Grid
                      container
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        {device.status === 'Online' ? (
                          <CheckCircleOutlineIcon color="success" />
                        ) : (
                          <HighlightOffIcon color="error" />
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {device.lastOnlineAt?.toLocaleString() || '-'}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={loading}
                      onClick={async () => {
                        await devicesService.rebootDevice(device.id);
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DeviceTable;
