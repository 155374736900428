import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { TileContainer } from './styles';

interface UsageTileProps {
  title: string;
  value: number;
  loading: boolean;
}

const UsageTile: React.FC<UsageTileProps> = ({ title, value, loading }) => {
  const formattedValue = !loading
    ? `${(value / 1024 / 1024 / 1024).toFixed(2)} GB`
    : null;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <TileContainer>
        <Typography variant="h4">{title}</Typography>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={25}
          >
            <CircularProgress size={18} />
          </Box>
        ) : (
          <Typography variant="h6">{formattedValue}</Typography>
        )}
      </TileContainer>
    </Grid>
  );
};

export default UsageTile;
