import { styled } from '@mui/material/styles';
import { Box, Card, Container, Paper } from '@mui/material';

export const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  maxWidth: 'lg'
});

export const StyledBox = styled(Box)({
  flexGrow: 1,
  paddingRight: '16px',
  width: '100%'
});

export const StyledSideBox = styled(Box)({
  width: '100%',
  marginTop: '16px',
  '@media (min-width: 960px)': {
    width: '300px',
    marginTop: 0
  }
});

export const StyledCard = styled(Card)({
  maxWidth: 300,
  minWidth: 250,
  margin: '0 auto',
  padding: '16px',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
});

export const StyledPaper = styled(Paper)({
  padding: '16px',
  marginBottom: '32px'
});

export const StyledModalBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '95%',
  width: '100%',
  maxHeight: '100vh',
  overflow: 'auto',
  padding: '16px',
  transition: 'height 0.5s ease-in-out, width 0.5s ease-in-out'
});
