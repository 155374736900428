import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import proxiesService from '../../../services/ProxiesService';
import { ProxyTable } from './ProxyTable';
import StatusTiles from './StatusTiles';

const Proxies: React.FC = () => {
  const { proxies, error, loading } = proxiesService.useStore((state) => state);
  const [selectedProxies, setSelectedProxies] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    (async function () {
      await proxiesService.fetchProxies();
    })();
  }, []);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = proxies.map((proxy) => proxy.id);
      setSelectedProxies(newSelected);
    } else {
      setSelectedProxies([]);
    }
  };

  const handleSelect = (id: string) => {
    const newSelected = new Set(selectedProxies);

    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }

    setSelectedProxies(Array.from(newSelected));
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadCSV = (type: 'socks5' | 'http') => {
    const proxiesToExport =
      selectedProxies.length > 0
        ? selectedProxies
        : proxies.map((proxy) => proxy.id);
    proxiesService.exportProxies(proxiesToExport, type);
    handleMenuClose();
  };

  if (error)
    return <Typography color="error">Error loading data: {error}</Typography>;

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ mt: 2, boxShadow: 3, borderRadius: 1 }}>
        <CardHeader
          title={
            <Typography variant="h4" component="div">
              Proxies
            </Typography>
          }
          action={
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleMenuOpen}
                  disabled={loading}
                  endIcon={<ArrowDropDownIcon />}
                >
                  {selectedProxies.length > 0
                    ? 'Download Selected'
                    : 'Download'}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleDownloadCSV('socks5')}>
                    Download SOCKS5
                  </MenuItem>
                  <MenuItem onClick={() => handleDownloadCSV('http')}>
                    Download HTTP
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={async () => {
                    await proxiesService.createProxy();
                  }}
                  disabled={loading}
                >
                  Create Proxy
                </Button>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <CardContent>
          <ProxyTable
            selectedProxies={selectedProxies}
            handleSelectAll={handleSelectAll}
            handleSelect={handleSelect}
          />
        </CardContent>
      </Card>
      <Box
        sx={{
          mt: 5,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <StatusTiles />
      </Box>
    </Box>
  );
};

export default Proxies;
