import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper
} from '@mui/material';

export const StyledContainer = styled(Container)({
  padding: '16px',
  maxWidth: 'lg'
});

export const StyledBox = styled(Box)({
  marginTop: '32px'
});

export const StyledPaper = styled(Paper)({
  padding: '20px'
});

export const StyledButton = styled(Button)({
  marginTop: '16px'
});

export const StyledIconButton = styled(IconButton)({
  marginLeft: '8px'
});

export const StyledGrid = styled(Grid)({
  padding: '20px',
  textAlign: 'center'
});

export const StyledDivider = styled(Divider)({
  marginBottom: '16px'
});
