import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import affiliateService from 'src/services/AffiliateService';
import LoadingBlock from 'src/components/LoadingBlock';
import {
  StyledBox,
  StyledButton,
  StyledContainer,
  StyledDivider,
  StyledIconButton,
  StyledPaper
} from './styles';

function AffiliateScreen() {
  const {
    loading,
    showStats,
    affiliateData,
    affiliateCode,
    getAffiliateData,
    getAffiliateCode,
    getPlans,
    getPlanById
  } = affiliateService.useStore();

  const [copySuccess, setCopySuccess] = React.useState<boolean>(false);

  const trackingUrl = useMemo(
    () => `https://buy.puroproxies.com/${affiliateCode}`,
    [affiliateCode]
  );

  useEffect(() => {
    getAffiliateCode();
    getPlans();
  }, [getAffiliateCode, getPlans]);

  useEffect(() => {
    if (affiliateCode != null) {
      getAffiliateData();
    }
  }, [affiliateCode, getAffiliateData]);

  const handleCopy = () => {
    if (affiliateCode) {
      navigator.clipboard.writeText(trackingUrl);
      setCopySuccess(true);
    }
  };

  const handleCloseSnackbar = () => {
    setCopySuccess(false);
  };

  const handleRequestPayout = () => {
    (window as any).$chatwoot.toggle();
    (window as any).$chatwoot.setConversationCustomAttributes({
      issueType: 'Affiliate payout',
      urgency: 'High'
    });
    (window as any).$chatwoot.setConversation({
      message: 'I would like to request a payout for my affiliate earnings.'
    });
  };

  if (loading) {
    return (
      <StyledContainer>
        <LoadingBlock />
      </StyledContainer>
    );
  }

  if (showStats) {
    return (
      <StyledContainer>
        <Snackbar
          open={copySuccess}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            Affiliate link copied to your clipboard!
          </Alert>
        </Snackbar>
        <StyledBox>
          <StyledPaper elevation={3}>
            <Typography variant="h4" gutterBottom>
              Affiliate Program
            </Typography>
            <Typography variant="body1" gutterBottom>
              Earn 25% of every subscription made through your link. Share now
              and start earning!
            </Typography>
            <Box display="flex" alignItems="center" mt={2} mb={2}>
              <Typography variant="body1">
                Your affiliate link:
                <Box
                  component="span"
                  ml={1}
                  p={1}
                  bgcolor="background.paper"
                  borderRadius={1}
                  sx={{ backgroundColor: '#f0f0f0' }}
                >
                  {trackingUrl}
                </Box>
              </Typography>
              <Tooltip title="Copy to clipboard">
                <StyledIconButton aria-label="copy" onClick={handleCopy}>
                  <FileCopyIcon />
                </StyledIconButton>
              </Tooltip>
            </Box>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleRequestPayout}
            >
              Request Payout
            </StyledButton>
          </StyledPaper>
        </StyledBox>

        <StyledBox>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <StyledPaper elevation={3}>
                <Typography variant="h6">Click Through Rate</Typography>
                <Typography variant="h3">
                  {affiliateData?.clickCount || 0}
                </Typography>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledPaper elevation={3}>
                <Typography variant="h6">Registered</Typography>
                <Typography variant="h3">
                  {affiliateData?.registerCount || 0}
                </Typography>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledPaper elevation={3}>
                <Typography variant="h6">Purchases Made</Typography>
                <Typography variant="h3">
                  {affiliateData?.purchaseCount || 0}
                </Typography>
              </StyledPaper>
            </Grid>
          </Grid>
        </StyledBox>

        <StyledBox>
          <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>
              History of Referred User Purchases
            </Typography>
            <StyledDivider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Paid</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {affiliateData?.payouts.map((payout) => {
                  return (
                    <TableRow key={payout.id}>
                      <TableCell>${(payout.amount / 100).toFixed(2)}</TableCell>
                      <TableCell>{payout.paid ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        {new Date(payout.created).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledPaper>
        </StyledBox>
      </StyledContainer>
    );
  } else {
    return <StyledContainer />;
  }
}

export default AffiliateScreen;
