/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductIdQuantity
 */
export interface ProductIdQuantity {
    /**
     * 
     * @type {string}
     * @memberof ProductIdQuantity
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductIdQuantity
     */
    quantity: number;
}

/**
 * Check if a given object implements the ProductIdQuantity interface.
 */
export function instanceOfProductIdQuantity(value: object): value is ProductIdQuantity {
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    return true;
}

export function ProductIdQuantityFromJSON(json: any): ProductIdQuantity {
    return ProductIdQuantityFromJSONTyped(json, false);
}

export function ProductIdQuantityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductIdQuantity {
    if (json == null) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'quantity': json['quantity'],
    };
}

export function ProductIdQuantityToJSON(value?: ProductIdQuantity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productId': value['productId'],
        'quantity': value['quantity'],
    };
}

