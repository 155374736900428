/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeSubscriptionPlanRequest,
  ChangeSubscriptionPlanResponse,
  Subscription,
} from '../models/index';
import {
    ChangeSubscriptionPlanRequestFromJSON,
    ChangeSubscriptionPlanRequestToJSON,
    ChangeSubscriptionPlanResponseFromJSON,
    ChangeSubscriptionPlanResponseToJSON,
    SubscriptionFromJSON,
    SubscriptionToJSON,
} from '../models/index';

export interface ApiSubscriptionsSubIdChangePostRequest {
    subId: string;
    changeSubscriptionPlanRequest: ChangeSubscriptionPlanRequest;
}

export interface ApiSubscriptionsSubIdDeleteRequest {
    subId: string;
}

export interface ApiSubscriptionsSubIdGetRequest {
    subId: string;
}

/**
 * 
 */
export class SubscriptionsApi extends runtime.BaseAPI {

    /**
     * Gets all subscription
     */
    async apiSubscriptionsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Subscription>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubscriptionFromJSON));
    }

    /**
     * Gets all subscription
     */
    async apiSubscriptionsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Subscription>> {
        const response = await this.apiSubscriptionsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSubscriptionsSubIdChangePostRaw(requestParameters: ApiSubscriptionsSubIdChangePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChangeSubscriptionPlanResponse>> {
        if (requestParameters['subId'] == null) {
            throw new runtime.RequiredError(
                'subId',
                'Required parameter "subId" was null or undefined when calling apiSubscriptionsSubIdChangePost().'
            );
        }

        if (requestParameters['changeSubscriptionPlanRequest'] == null) {
            throw new runtime.RequiredError(
                'changeSubscriptionPlanRequest',
                'Required parameter "changeSubscriptionPlanRequest" was null or undefined when calling apiSubscriptionsSubIdChangePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/subscriptions/{subId}/change`.replace(`{${"subId"}}`, encodeURIComponent(String(requestParameters['subId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeSubscriptionPlanRequestToJSON(requestParameters['changeSubscriptionPlanRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeSubscriptionPlanResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSubscriptionsSubIdChangePost(requestParameters: ApiSubscriptionsSubIdChangePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChangeSubscriptionPlanResponse> {
        const response = await this.apiSubscriptionsSubIdChangePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel subscription.
     */
    async apiSubscriptionsSubIdDeleteRaw(requestParameters: ApiSubscriptionsSubIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters['subId'] == null) {
            throw new runtime.RequiredError(
                'subId',
                'Required parameter "subId" was null or undefined when calling apiSubscriptionsSubIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/subscriptions/{subId}`.replace(`{${"subId"}}`, encodeURIComponent(String(requestParameters['subId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
     * Cancel subscription.
     */
    async apiSubscriptionsSubIdDelete(requestParameters: ApiSubscriptionsSubIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.apiSubscriptionsSubIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets more information on an existing subscription for the user from stripe
     * Get subscription information
     */
    async apiSubscriptionsSubIdGetRaw(requestParameters: ApiSubscriptionsSubIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscription>> {
        if (requestParameters['subId'] == null) {
            throw new runtime.RequiredError(
                'subId',
                'Required parameter "subId" was null or undefined when calling apiSubscriptionsSubIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/api/subscriptions/{subId}`.replace(`{${"subId"}}`, encodeURIComponent(String(requestParameters['subId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionFromJSON(jsonValue));
    }

    /**
     * Gets more information on an existing subscription for the user from stripe
     * Get subscription information
     */
    async apiSubscriptionsSubIdGet(requestParameters: ApiSubscriptionsSubIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscription> {
        const response = await this.apiSubscriptionsSubIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
