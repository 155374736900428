import { create } from 'zustand';
import { apiConfig } from 'src/api';
import { AccountsApi } from 'src/api/apis/AccountsApi';
import { Account } from 'src/api/models/Account';
import { LoadingState } from '../util/LoadingState';
import { UpdateAccountRequest } from 'src/api/models';

interface AccountState extends LoadingState {
  account: Account | null;
  getCount: number;
  affiliateCode: string | null;
  getAccountData: () => Promise<Account | void>;
  getTotalDataAllocated: () => number;
  getTotalDataRemaining: () => number;
}

const useAccountStore = create<AccountState>((set) => ({
  account: null,
  loading: false,
  getCount: 0,
  affiliateCode: null,
  getAccountData: async () => {
    set((state) => ({ loading: true, getCount: state.getCount + 1 }));
    try {
      const accountsApi = new AccountsApi(apiConfig);
      const acc = await accountsApi.apiAccountsMeGet();
      set({
        account: acc,
        affiliateCode: acc.affiliateCode || null,
        loading: false
      });
      return acc;
    } catch (error) {
      console.error('Failed to get account data:', error);
      set({ loading: false });
    }
  },
  getTotalDataAllocated: () => {
    return useAccountStore
      .getState()
      .account?.dataPacks.map((d) => d.bytesTotal)
      .reduce((partialSum, a) => partialSum + a, 0);
  },
  getTotalDataRemaining: () => {
    return useAccountStore
      .getState()
      .account?.dataPacks.map((d) => d.bytesRemaining)
      .reduce((partialSum, a) => partialSum + a, 0);
  }
}));

class AccountService {
  useStore = useAccountStore;
  getAccountData = async () => this.useStore.getState().getAccountData();
  getTotalDataAllocated = () =>
    this.useStore.getState().getTotalDataAllocated();
  getTotalDataRemaining = () =>
    this.useStore.getState().getTotalDataRemaining();

  changeProxyPassword = async (newPassword: string) => {
    const accountsApi = new AccountsApi(apiConfig);
    const updateRequest: UpdateAccountRequest = { proxyPassword: newPassword };

    try {
      const updatedAccount = await accountsApi.apiAccountsMePut({
        updateAccountRequest: updateRequest
      });
      this.useStore.setState({ account: updatedAccount, loading: false });
      return updatedAccount;
    } catch (error) {
      console.error('Failed to change password:', error);
      this.useStore.setState({ loading: false });
      throw error;
    }
  };

  changeAffiliateCode = async (newAffiliateCode: string) => {
    const accountsApi = new AccountsApi(apiConfig);
    const updateRequest: UpdateAccountRequest = {
      affiliateCode: newAffiliateCode
    };

    try {
      const updatedAccount = await accountsApi.apiAccountsMePut({
        updateAccountRequest: updateRequest
      });
      this.useStore.setState({
        account: updatedAccount,
        affiliateCode: updatedAccount.affiliateCode,
        loading: false
      });
      return updatedAccount;
    } catch (error) {
      console.error('Failed to change affiliate code:', error);
      this.useStore.setState({ loading: false });
      throw error;
    }
  };
}

const accountService = new AccountService();
export default accountService;
