/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface CreateProxiesRequest
 */
export interface CreateProxiesRequest {
    /**
     * 
     * @type {Location}
     * @memberof CreateProxiesRequest
     */
    location: Location;
    /**
     * 
     * @type {number}
     * @memberof CreateProxiesRequest
     */
    amount?: number;
}

/**
 * Check if a given object implements the CreateProxiesRequest interface.
 */
export function instanceOfCreateProxiesRequest(value: object): value is CreateProxiesRequest {
    if (!('location' in value) || value['location'] === undefined) return false;
    return true;
}

export function CreateProxiesRequestFromJSON(json: any): CreateProxiesRequest {
    return CreateProxiesRequestFromJSONTyped(json, false);
}

export function CreateProxiesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProxiesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'location': LocationFromJSON(json['location']),
        'amount': json['amount'] == null ? undefined : json['amount'],
    };
}

export function CreateProxiesRequestToJSON(value?: CreateProxiesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': LocationToJSON(value['location']),
        'amount': value['amount'],
    };
}

