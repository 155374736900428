import { CircularProgress } from '@mui/material';
import { Container } from './styles';

const LoadingBlock = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

export default LoadingBlock;
