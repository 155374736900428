import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import { Proxy } from 'src/api/models/Proxy';

interface UsernameDialogProps {
  open: boolean;
  handleClose: (success: boolean) => void;
  proxy: Proxy | null;
}

const UsernameDialog: React.FC<UsernameDialogProps> = ({
  open,
  handleClose,
  proxy
}) => {
  const [username, setUsername] = useState(proxy?.username || '');

  const handleSave = async () => {
    if (proxy) {
      try {
        handleClose(true);
      } catch (error) {
        console.error('Failed to update username:', error);
        handleClose(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>Change Username</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the new username for the proxy.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Username"
          type="text"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsernameDialog;
