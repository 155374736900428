/* tslint:disable */
/* eslint-disable */
/**
 * PuroProxies
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductType } from './ProductType';
import {
    ProductTypeFromJSON,
    ProductTypeFromJSONTyped,
    ProductTypeToJSON,
} from './ProductType';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    costCents: number;
    /**
     * 
     * @type {ProductType}
     * @memberof Product
     */
    productType: ProductType;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    stripeId: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    dataPackBytesSize?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    stripePriceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    subscription: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    active: boolean;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): value is Product {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('costCents' in value) || value['costCents'] === undefined) return false;
    if (!('productType' in value) || value['productType'] === undefined) return false;
    if (!('stripeId' in value) || value['stripeId'] === undefined) return false;
    if (!('stripePriceId' in value) || value['stripePriceId'] === undefined) return false;
    if (!('subscription' in value) || value['subscription'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    return true;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'costCents': json['costCents'],
        'productType': ProductTypeFromJSON(json['productType']),
        'stripeId': json['stripeId'],
        'dataPackBytesSize': json['dataPackBytesSize'] == null ? undefined : json['dataPackBytesSize'],
        'stripePriceId': json['stripePriceId'],
        'subscription': json['subscription'],
        'active': json['active'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'costCents': value['costCents'],
        'productType': ProductTypeToJSON(value['productType']),
        'stripeId': value['stripeId'],
        'dataPackBytesSize': value['dataPackBytesSize'],
        'stripePriceId': value['stripePriceId'],
        'subscription': value['subscription'],
        'active': value['active'],
    };
}

