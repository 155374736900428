import { Box, CircularProgress, TableCell } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
