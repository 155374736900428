import { AffiliatesApi } from 'src/api/apis/AffiliatesApi';
import { AffiliateResponse } from 'src/api/models/AffiliateResponse';
import plansService from './PlansService';
import { Plan } from 'src/api/models/Plan';
import { create } from 'zustand';
import { apiConfig } from '../api';
import { LoadingState } from '../util/LoadingState';
import { AccountsApi } from 'src/api/apis/AccountsApi';

interface AffiliateState extends LoadingState {
  showStats: boolean;
  affiliateData: AffiliateResponse | null;
  affiliateCode: string | null;
  plans: Plan[];
  getAffiliateData: () => void;
  getAffiliateCode: () => void;
  getPlans: () => void;
  getPlanById: (planId: string) => Plan | undefined;
}

const useAffiliateStore = create<AffiliateState>((set, get) => ({
  loading: false,
  showStats: false,
  affiliateData: null,
  affiliateCode: null,
  plans: [],

  getAffiliateData: async () => {
    set({ loading: true });
    try {
      const affiliatesApi = new AffiliatesApi(apiConfig);
      const res = await affiliatesApi.apiAffiliatesGet();
      set({ affiliateData: res, loading: false });
    } catch (error) {
      console.error('Failed to get affiliate data:', error);
      set({ loading: false });
    }
  },
  getAffiliateCode: async () => {
    set({ loading: true });
    try {
      const accountsApi = new AccountsApi(apiConfig);
      const res = await accountsApi.apiAccountsMeGet();
      set({
        affiliateCode: res.affiliateCode || null,
        showStats: !!res.affiliateCode,
        loading: false
      });
    } catch (error) {
      console.error('Failed to get affiliate code:', error);
      set({ loading: false });
    }
  },
  getPlans: async () => {
    set({ loading: true });
    try {
      const plans = await plansService.getPlans();
      set({ plans, loading: false });
    } catch (error) {
      console.error('Failed to get plans:', error);
      set({ loading: false });
    }
  },
  getPlanById: (planId: string) =>
    get().plans.find((plan) => plan.id === planId)
}));

class AffiliateService {
  useStore = useAffiliateStore;
  getAffiliateData = async () => this.useStore.getState().getAffiliateData();
  getAffiliateCode = async () => this.useStore.getState().getAffiliateCode();
  getPlans = async () => this.useStore.getState().getPlans();
  getPlanById = (planId: string) =>
    this.useStore.getState().getPlanById(planId);
}

const affiliateService = new AffiliateService();
export default affiliateService;
