const colors = {
  warn: '#f9903d',
  white: '#f7f7ff',
  gray: '#545e75',
  success: '#3f826d',
  purple: '#791E94',
  blue: '#256EFF',
  brightGreen: '#3DDC97',
  brightRed: '#FF495C',
  darkPurple: '#46237A'
};

export default colors;
