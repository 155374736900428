import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField
} from '@mui/material';
import { Product } from 'src/api/models/Product';
import { ModalBox } from './styles';

const MutateProduct = ({
  product,
  onClose,
  open,
  onChange,
  onSave
}: {
  product: Product;
  onClose: () => void;
  open: boolean;
  onChange: (Product) => void;
  onSave: () => void;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBox>
        <TextField
          label="Name"
          variant="outlined"
          value={product.name}
          onChange={(e) => {
            onChange({
              ...product,
              name: e.target.value
            });
          }}
        />
        <TextField
          label="Description"
          variant="outlined"
          value={product.description}
          onChange={(e) => {
            onChange({
              ...product,
              description: e.target.value
            });
          }}
        />

        <TextField
          type="number"
          label="Cost In Cents"
          variant="outlined"
          value={product.costCents}
          onChange={(e) => {
            onChange({
              ...product,
              costCents: Number(e.target.value)
            });
          }}
        />
        <FormControl>
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            value={product.productType}
            label="Type"
            onChange={(e) => {
              onChange({
                ...product,
                productType: e.target.value
              });
            }}
          >
            <MenuItem value="DataPack">Data Pack</MenuItem>
          </Select>
        </FormControl>

        {product.productType === 'DataPack' && (
          <TextField
            type="number"
            label="Data Pack Size (GB)"
            variant="outlined"
            value={(product.dataPackBytesSize || 0) / 1024 / 1024 / 1024}
            onChange={(e) => {
              onChange({
                ...product,
                dataPackBytesSize: Number(e.target.value) * 1024 * 1024 * 1024
              });
            }}
          />
        )}

        <FormControlLabel
          control={
            <Switch
              checked={product.active}
              onChange={(e) => {
                onChange({
                  ...product,
                  active: e.target.checked
                });
              }}
            />
          }
          label="Active"
        />

        <Button onClick={onSave} variant="contained">
          Save
        </Button>
      </ModalBox>
    </Modal>
  );
};

export default MutateProduct;
