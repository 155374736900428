import { PlansApi } from 'src/api/apis/PlansApi';
import { Plan } from 'src/api/models/Plan';
import { LoadingState } from '../util/LoadingState';
import { create } from 'zustand';
import { apiConfig } from '../api';

interface PlanState extends LoadingState {
  plans: Plan[];
  getPlans: () => Promise<Plan[]>;
  savePlan: (plan: Plan) => Promise<void>;
}

const usePlansStore = create<PlanState>((set) => ({
  plans: [],
  loading: false,
  getPlans: async () => {
    set({ loading: true });
    try {
      const plansApi = new PlansApi(apiConfig);
      const plans = await plansApi.apiPlansGet();
      set({ plans, loading: false });
      return plans;
    } catch (error) {
      console.error('Failed to fetch plans:', error);
      set({ loading: false });
      throw error;
    }
  },
  savePlan: async (plan: Plan) => {
    set({ loading: true });
    try {
      const plansApi = new PlansApi(apiConfig);
      await plansApi.apiPlansPost({ plan });
      await usePlansStore.getState().getPlans();
    } catch (error) {
      console.error('Failed to save plan:', error);
      set({ loading: false });
      throw error;
    }
  }
}));

class PlansService {
  useStore = usePlansStore;
  getPlans = async () => this.useStore.getState().getPlans();
  savePlan = async (plan: Plan) => this.useStore.getState().savePlan(plan);
}

const plansService = new PlansService();
export default plansService;
