import { CheckoutApi } from 'src/api/apis/CheckoutApi';
import { CheckoutRequest, ProductIdQuantity } from 'src/api/models';
import { LoadingState } from '../util/LoadingState';
import { create } from 'zustand';
import { apiConfig } from '../api';

interface CheckoutState extends LoadingState {
  createCheckoutSession: (
    products: Map<string, number>,
    subId: string
  ) => Promise<string>;
}

const useCheckoutStore = create<CheckoutState>((set) => ({
  loading: false,
  createCheckoutSession: async (
    products: Map<string, number>,
    subId: string
  ) => {
    set({ loading: true });
    try {
      const checkoutApi = new CheckoutApi(apiConfig);
      const checkoutProducts: ProductIdQuantity[] = Array.from(products).map(
        ([productId, quantity]) => ({
          productId,
          quantity
        })
      );
      const request: CheckoutRequest = {
        productIdQuantites: checkoutProducts,
        planId: subId || undefined
      };
      const response = await checkoutApi.apiCheckoutPost({
        checkoutRequest: request
      });
      return response.key;
    } catch (error) {
      console.error('Failed to start checkout process:', error);
      throw error;
    } finally {
      set({ loading: false });
    }
  }
}));

class CheckoutService {
  useStore = useCheckoutStore;
  createCheckoutSession = async (
    products: Map<string, number>,
    subId: string
  ) => this.useStore.getState().createCheckoutSession(products, subId);
}

const checkoutService = new CheckoutService();
export default checkoutService;
