import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { AuthProvider } from './contexts/AuthContext';
import { GlobalStyle } from './styles';

function App() {
  const content = useRoutes(router);
  return (
    <AuthProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <GlobalStyle />
          {content}
        </LocalizationProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
