import React from 'react';
import { Button, CardActions, CardContent, Typography } from '@mui/material';
import { StyledCard } from './styles';

interface ProductTileProps {
  product: {
    name: string;
    costCents: number;
    subscription: boolean;
    stripePriceId: string;
    id: string;
    freeData: string | null;
  };
  addToBasket: () => void;
}

const ProductTile: React.FC<ProductTileProps> = ({ product, addToBasket }) => {
  const formatPrice = (price: number) => (price / 100).toFixed(2);

  return (
    <StyledCard>
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{ fontWeight: 'medium' }}
        >
          {product.name}
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          sx={{ fontWeight: 'bold', mb: 2 }}
        >
          {product.subscription
            ? `Price: $${formatPrice(product.costCents)}/month`
            : `Price: $${formatPrice(product.costCents)}`}
        </Typography>
        {product.subscription && product.freeData && (
          <Typography
            variant="body1"
            color="secondary"
            sx={{ fontWeight: 'bold', mb: 2 }}
          >
            + {product.freeData}MB Starter Data pack
          </Typography>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', padding: '16px' }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          onClick={addToBasket}
          sx={{ textTransform: 'none', fontWeight: 'medium' }}
        >
          Add to Cart
        </Button>
      </CardActions>
    </StyledCard>
  );
};

export default ProductTile;
