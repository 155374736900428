import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.warning.main
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1)
}));
